import React, { useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

const UserBottomNav = ({ handleMenuSwitch }) => {
  const [value, setValue] = useState('Bearbeiten');

  const handleChange = (event, newValue) => {
    // 'event' is required by MUI
    setValue(newValue);
    handleMenuSwitch(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className='bottomNav'
    >
      <BottomNavigationAction
        label='Informationen'
        value='Bearbeiten'
        icon={<PersonIcon />}
      />
      <BottomNavigationAction
        label='Passwort'
        value='Passwort'
        icon={<LockIcon />}
      />
    </BottomNavigation>
  );
};

export default UserBottomNav;
