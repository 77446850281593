import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const MuiThemeProvider = ({ themeLight, children }) => {
  return themeLight ? (
    <ThemeProvider theme={theme_light}>{children}</ThemeProvider>
  ) : (
    <ThemeProvider theme={theme_Dark}>{children}</ThemeProvider>
  );
};

export default MuiThemeProvider;

// TODO: Outsourcing themes

const theme_light = createMuiTheme({
  palette: {
    primary: {
      main: '#dc4605',
    },
    secondary: {
      main: '#006987',
    },
    error: {
      main: '#f44336',
    },
  },
});

const theme_Dark = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#dc4605',
    },
    secondary: {
      main: '#006987',
    },
  },
  /* typography: {
    fontFamily: 'Signika Negative',
  },*/
});
