import { useState, useEffect } from 'react';

const weekDays = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
];

export const monthDays = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const getTime = () => {
  const date = new Date();

  // timeString
  const hours = date.getHours();
  const mins = date.getMinutes();
  const minutes = mins < 10 || mins === 0 ? `0${mins}` : mins; // add zero
  const timeString = `${hours}:${minutes}`;

  return timeString;
};

const getDate = () => {
  const date = new Date();

  // dateString
  const dayOfWeek = weekDays[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthDays[date.getMonth()];
  const year = date.getFullYear();
  const dateString = `${dayOfWeek} , ${dayOfMonth}. ${month} ${year}`;

  return dateString;
};

// Timer Hook (reusable)
export const useTimer = () => {
  const [timeString, setTimeString] = useState(getTime);
  const [dateString, setDateString] = useState(getDate);

  // reset time every second
  useEffect(() => {
    const timeout = setInterval(() => {
      setTimeString(getTime);
      setDateString(getDate);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [timeString, dateString]);

  return [timeString, dateString];
};
