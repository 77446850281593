import React, { useState, useEffect, useContext, Fragment } from 'react';
import LoadingAnimation from '../shared/LoadingAnimation';
import { postAssetFunctions } from '../../utils/api/templates';
import { getParsedCalendarEvents } from '../../utils/helpers/getParsedCalendarEvents';
import ProfileContext from '../../context/profile/profileContext';
import Icon from '@material-ui/core/Icon';

const Calendar = () => {
  const { config, user } = useContext(ProfileContext);

  // state (component will be rerendered when state changed)
  const [activeEvents, setActiveEvent] = useState(null);
  const [futureEvents, setFutureEvents] = useState(null);
  const [error, setError] = useState(false);
  const [daysInFuture, setDaysInFuture] = useState(14);
  const [clickMoreDaysButton, setclickMoreDaysButton] = useState(true);

  // will be executed when component is first rendered
  useEffect(() => {
    fetchCalendarData();
  }, [daysInFuture]);

  // fetch new calendar data by selected interval
  useEffect(() => {
    const interval = setInterval(() => {
      fetchCalendarData();
    }, config.refreshRateInSeconds.calendarData * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [config]);

  const fetchCalendarData = () => {
    postAssetFunctions('getCal').then((res) => {
      if (res !== null && typeof res !== 'undefined') {
        setActiveAndFutureEvents(getParsedCalendarEvents(res, config));
      } else {
        setError(true);
      }
    });
  };

  const handleClickOnLoadMore = () => {
    setDaysInFuture(365);
    setclickMoreDaysButton(false);
    setActiveEvent(null);
  };

  // set state (activeEvents, futureEvents)
  const setActiveAndFutureEvents = (events) => {
    let activeEvents = [];
    let futureEvents = [];

    events.forEach((e, index) => {
      let event = (
        <div key={index} className={`cal-widget-item eventcat-${e.eventcat}`}>
          <div
            style={{ color: config[e.eventcat.toLowerCase()]['color'] }}
            className='cal-widget-item-icon material-icons'
          >
            <Icon>{config[e.eventcat.toLowerCase()]['badge']}</Icon>
          </div>
          <div className={`cal-widget-item-user`}>{e.summary}</div>
          <div className={`cal-widget-item-type`}>
            {window.innerWidth > 500 ? e.eventlocation + ' ' : ''}
            <b>{e.eventduration}</b>
          </div>
          <div className={`cal-widget-item-daterange`}>{e.daterange}</div>
        </div>
      );
      if (e.activeevent) {
        activeEvents.push(event);
      } else if (e.sortingparam <= daysInFuture) {
        futureEvents.push(event);
      }
    });

    setActiveEvent([activeEvents]);
    setFutureEvents([futureEvents]);
  };

  return (
    <div className='calendar-wrapper'>
      {user.role !== 'Device' && (
        <div id='cal-widget'>
          <div id='cal-widget-wrapper'>
            {activeEvents === null || futureEvents === null || error ? (
              <Fragment>
                {error ? (
                  <p>Kalender-Daten konnten nicht geladen werden.</p>
                ) : (
                  <LoadingAnimation />
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div id='cal-widget-active'>
                  <h4>Aktuell</h4>
                  {activeEvents.length > 0 ? (
                    activeEvents
                  ) : (
                    <p>heute keine Einträge</p>
                  )}
                </div>
                <div id='#cal-widget-future'>
                  <h4>Anstehend</h4>
                  {futureEvents.length > 0 ? (
                    futureEvents
                  ) : (
                    <p>keine Einträge in naher Zukunft</p>
                  )}
                  {clickMoreDaysButton && (
                    <a
                      id='cal-widget-loadmore'
                      onClick={() => handleClickOnLoadMore()}
                    >
                      <span
                        id='cal-widget-loadmore-icon'
                        className='material-icons'
                      >
                        sync
                      </span>
                      <span id='cal-widget-loadmore-text'>
                        weitere Einträge laden
                      </span>
                    </a>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;

// <div className={`cal-widget-item-duration`}>{e.eventduration}</div>
