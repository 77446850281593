export const calculateStatusItemsPositions = () => {
  var elements = document.querySelectorAll('.menuOverlay.shown');

  for (var i = 0; i < elements.length; i++) {
    let childs = elements[i].children;

    for (var j = 0, l = childs.length; j < l; j++) {
      childs[j].style.left =
        (
          50 -
          35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * j * Math.PI)
        ).toFixed(4) + '%';

      childs[j].style.top =
        (
          50 +
          35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * j * Math.PI)
        ).toFixed(4) + '%';
    }
  }
};
