import React from 'react';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const TabMenuWrapper = ({ children, clickAwayHandler }) => {
  const handleClickAway = (e) => {
    if (e.target.classList.contains('tabMenu-wrapper')) {
      clickAwayHandler();
    }
  };

  return (
    <div className='tabMenu-wrapper'>
      <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
        <Paper className='tabMenu'>{children}</Paper>
      </ClickAwayListener>
    </div>
  );
};

export default TabMenuWrapper;
