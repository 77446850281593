import * as ICAL from 'ical.js';

export const getParsedCalendarEvents = (iCalendarData, config) => {
  var jcalData = ICAL.parse(iCalendarData);
  var comp = new ICAL.Component(jcalData);
  var vevent = comp.getAllSubcomponents('vevent');

  var nowISOdate = new Date().toISOString().split('T')[0].replace(/-/g, '');
  var today = new Date();
	today.setHours(0,0,0,0);
  var thisyear = today.getFullYear();
  var dateOffset = 24 * 60 * 60 * 1000 * 1;

  var events_sorted = [];

  for (var v in vevent) {
    var event = new ICAL.Event(vevent[v]);

    var summary = event.summary;
    var eventlocation = event.location != null ? event.location : '';
    var organizer = event.organizer;
		
    var startdate = event.startDate.toICALString().split('T')[0];
    var enddate = event.endDate.toICALString().split('T')[0];

		var startdateISO = event.startDate.toJSDate().toISOString().split('T')[0].replace(/-/g, "");
		var enddateISO = event.endDate.toJSDate().toISOString().split('T')[0].replace(/-/g, "");
		
		var startdateREAL = startdate;
		var enddateREAL = enddateISO;

    var enddateJS = new Date(event.endDate.toJSDate());
    enddateJS.setTime(enddateJS.getTime() - dateOffset);
		
		var onedayevent = event.duration.days === 1 ? true : false;
    var activeevent = (!onedayevent && startdateREAL <= nowISOdate && nowISOdate <= enddateREAL) || (onedayevent && startdateREAL === nowISOdate) ? true : false;

    var daysuntilevent = Math.abs(event.startDate.toJSDate() - today);
    daysuntilevent = Math.ceil(daysuntilevent / (1000 * 60 * 60 * 24));

    var daysremaining = Math.abs(enddateJS - today);
    daysremaining = Math.ceil(daysremaining / (1000 * 60 * 60 * 24));

		/*if (activeevent) {
			console.log('### USER: ' + summary);
			console.log('### Math.abs(enddateJS - today): ' + Math.abs(enddateJS - today));
			console.log('### daysremaining / (1000 * 60 * 60 * 24)): ' + daysremaining / (1000 * 60 * 60 * 24));
			console.log('---');
			console.log('### event.startDate: ' + event.startDate);
			console.log('### event.endDate: ' + event.endDate);
			console.log('### enddateJS: ' + enddateJS);
			console.log('---');
			console.log('### startdate: ' + startdate);
			console.log('### enddate: ' + enddate);
			console.log('---');
			console.log('### startdateISO: ' + startdateISO);
			console.log('### enddateISO: ' + enddateISO);
			console.log('---');
			console.log('### startdateREAL: ' + startdateREAL);
			console.log('### enddateREAL: ' + enddateREAL);
			console.log('---');
			console.log('### today: ' + today);
			console.log('### nowISOdate: ' + nowISOdate);
			console.log('### daysremaining: ' + daysremaining);
			console.log(' ');console.log(' ');console.log(' ');
		}*/
		
    var sortingparam = '';
    var eventduration = 0;
    if (onedayevent && activeevent) {
      eventduration = 'heute';
      sortingparam = 0;
    } else if (activeevent && daysremaining === 0) {
      eventduration = 'noch heute';
      sortingparam = 0;
    } else if (!activeevent && daysuntilevent === 1) {
      eventduration = 'morgen';
      sortingparam = 1;
    } else if (!activeevent) {
      eventduration = 'in ' + daysuntilevent + ' Tagen';
      sortingparam = daysuntilevent;
    } else if (activeevent && daysremaining === 1) {
      eventduration = 'noch einen Tag';
      sortingparam = 1;
    } else if (activeevent) {
      eventduration = 'noch ' + daysremaining + ' Tage';
      sortingparam = daysremaining;
    }

    var daterange = '';
    if (!onedayevent) {
      var eventyearbeginn = '';
      var eventyearend = '';
      if (
        event.startDate.year !== thisyear ||
        event.endDate.year !== thisyear
      ) {
        eventyearbeginn = event.startDate.year + '';
        eventyearend = event.endDate.year + '';
      }
      daterange =
        event.startDate.day +
        '.' +
        event.startDate.month +
        '.' +
        eventyearbeginn +
        ' – ' +
        enddateJS.getDate() +
        '.' +
        parseInt(enddateJS.getMonth() + 1) +
        '.' +
        eventyearend;
    } else {
      daterange = event.startDate.day + '.' + event.startDate.month + '.';
    }

    // identify category by keywords (defined in config.json) - default: 'none'
    var eventloc = eventlocation.toLowerCase();
    var eventcat = 'none';

    Object.entries(config).forEach(([key, values], index) => {
      if (values.identifier && values.identifier.length > 0) {
        for (let i = 0; i < values.identifier.length; i++) {
          if (eventloc.indexOf(values.identifier[i]) >= 0) {
            eventcat = key;
            break;
          }
        }
      }
    });

    if (activeevent || startdate > nowISOdate) {
      var event_obj = {};
      event_obj.summary = summary;
      event_obj.eventlocation = eventlocation;
      event_obj.organizer = organizer;
      event_obj.startdate = startdate;
      event_obj.enddate = enddate;
      event_obj.eventduration = eventduration;
      event_obj.daterange = daterange;
      event_obj.activeevent = activeevent;
      event_obj.onedayevent = onedayevent;
      event_obj.daysuntilevent = daysuntilevent;
      event_obj.daysremaining = daysremaining;
      event_obj.sortingparam = sortingparam;
      event_obj.eventcat = eventcat;

      events_sorted.push(event_obj);
    }
  }

  /*const sortByKeyDesc = function (array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  };*/

  const sortByKeyAsc = function (array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  sortByKeyAsc(events_sorted, 'sortingparam');

  return events_sorted;
};
