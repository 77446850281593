import React, { useContext, useEffect, useState } from 'react';
import Profile from '../profile/Profile';
import SelectionInput from './SelectionInput';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '@material-ui/core/Button';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TextField } from '@material-ui/core';
import ProfileContext from '../../context/profile/profileContext';

/* ADD, EDIT, DELETE EMPLOYEE FORM */
const EmployeeForm = ({
  profile,
  handleBackButton,
  role,
  type,
  setRefreshKey,
  setImageMenu,
  setDescriptionMenu,
}) => {
  // context
  const { updateProfile, addProfile, deleteProfile } = useContext(
    ProfileContext
  );

  // state
  const [currentPayload, setPayload] = useState(
    profile ? { id: profile.id } : {}
  );
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [shortNameInput, setShortNameInput] = useState('');
  const [shortNameKey, setShortNameKey] = useState(0);

  // effect for setting short name by first- and last name
  useEffect(() => {
    if (firstNameInput.length >= 2 && lastNameInput.length >= 2) {
      let firstNameCharacters = `${firstNameInput
        .substring(0, 1)
        .toUpperCase()}${firstNameInput.substring(1, 2).toLowerCase()}`;
      let lastNameCharacters = `${lastNameInput
        .substring(0, 1)
        .toUpperCase()}${lastNameInput.substring(1, 2).toLowerCase()}`;

      setShortNameInput(firstNameCharacters + lastNameCharacters);
    } else {
      setShortNameInput('');
    }
    setShortNameKey((prev) => prev + 1);
  }, [firstNameInput, lastNameInput]);

  const handleSubmit = () => {
    // copy current payload
    let payload = Object.assign({}, currentPayload);

    if (type === 'delete') {
      // delete request
      deleteProfile(profile.id);
      handleBackButton();
    } else if (type === 'edit') {
      // update request
      Object.entries(payload).forEach(([key, val]) => {
        if (val === null) {
          delete payload[key];
        }
      });
      delete payload['locationId'];
      updateProfile(payload);
    } else if (type === 'add') {
      // delete unset entries
      Object.entries(payload).forEach(([key, val]) => {
        if (val === null) {
          delete payload[key];
        }
      });
      addProfile(payload);
    }
  };

  return (
    <div className='settings-form'>
      {type !== 'delete' && (
        <div className='form-row-Wrapper nameInputs required'>
          <FirstNameInput
            setFirstNameInput={setFirstNameInput}
            setPayload={setPayload}
            profile={profile}
          />
          <LastNameInput
            setLastNameInput={setLastNameInput}
            setPayload={setPayload}
            profile={profile}
          />
          <ShortNameInput
            refreshKey={shortNameKey}
            setPayload={setPayload}
            profile={profile}
            role={role}
            defaultInput={shortNameInput}
          />
        </div>
      )}
      {type !== 'delete' && (
        <div className='form-row-Wrapper second'>
          <BirthdateInput profile={profile} setPayload={setPayload} />
          <PhoneNumberInput profile={profile} setPayload={setPayload} />
        </div>
      )}
      {type !== 'delete' && (
        <div className='form-row-Wrapper required'>
          <LocationSelection
            setPayload={setPayload}
            profile={profile}
            role={role}
          />
          {role !== 'user' && (
            <RoleSelection setPayload={setPayload} profile={profile} />
          )}
          {role !== 'user' && (
            <TeamSelection setPayload={setPayload} profile={profile} />
          )}
          {role === 'user' && <ImageButton setImageMenu={setImageMenu} />}
          {role === 'user' && (
            <DescriptionButton setDescriptionMenu={setDescriptionMenu} />
          )}
        </div>
      )}
      {role !== 'user' && type === 'edit' && (
        <PasswordInput profile={profile} setPayload={setPayload} />
      )}
      {type === 'delete' && <DeleteEmployeeForm profile={profile} />}

      {handleBackButton && (
        <ArrowBackIosIcon className='back-button' onClick={handleBackButton} />
      )}
      <SubmitButton
        setRefreshKey={setRefreshKey}
        type={type}
        profile={profile}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default EmployeeForm;

/* CONSTANT COMPONENTS */

const FirstNameInput = ({ profile, setPayload, setFirstNameInput }) => {
  // state
  const [value, setValue] = useState(profile && profile.firstName);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState();

  // effect of value - handle validation state
  useEffect(() => {
    if (value === '') {
      // empty input - trigger error
      setError(true);
      setHelperText('Ungültige Eingabe');
      setPayload((prevState) => {
        return { ...prevState, firstName: null };
      });
    } else {
      // valid input - remove error
      setError(false);
      setHelperText('');

      if (profile) {
        // editing menu
        if (profile.firstName !== value) {
          // value has changed
          setPayload((prevState) => {
            return { ...prevState, firstName: value };
          });
        } else {
          // value has not changed
          setPayload((prevState) => {
            return { ...prevState, firstName: null };
          });
        }
      } else {
        // adding menu
        if (typeof value !== 'undefined') {
          // value was entered
          setPayload((prevState) => {
            return { ...prevState, firstName: value };
          });
        } else {
          setPayload((prevState) => {
            return { ...prevState, firstName: null };
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <TextField
      required={true}
      spellCheck={false}
      defaultValue={profile && profile.firstName}
      color='secondary'
      label='Vorname'
      variant='standard'
      error={error}
      helperText={helperText}
      onChange={(e) => {
        setValue(e.target.value);
        setFirstNameInput(e.target.value);
      }}
    />
  );
};

const LastNameInput = ({ profile, setPayload, setLastNameInput }) => {
  // state
  const [value, setValue] = useState(profile && profile.lastName);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState();

  // effect of value - handle validation state
  useEffect(() => {
    if (value === '') {
      // empty input - trigger error
      setError(true);
      setHelperText('Ungültige Eingabe');
      setPayload((prevState) => {
        return { ...prevState, lastName: null };
      });
    } else {
      // valid input - remove error
      setError(false);
      setHelperText('');

      if (profile) {
        // editing menu
        if (profile.lastName !== value) {
          // value has changed
          setPayload((prevState) => {
            return { ...prevState, lastName: value };
          });
        } else {
          // value has not changed
          setPayload((prevState) => {
            return { ...prevState, lastName: null };
          });
        }
      } else {
        // adding menu
        if (typeof value !== 'undefined') {
          // value was entered
          setPayload((prevState) => {
            return { ...prevState, lastName: value };
          });
        } else {
          setPayload((prevState) => {
            return { ...prevState, lastName: null };
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <TextField
      spellCheck={false}
      required={true}
      defaultValue={profile && profile.lastName}
      color='secondary'
      label='Nachname'
      variant='standard'
      error={error}
      helperText={helperText}
      onChange={(e) => {
        setValue(e.target.value);
        setLastNameInput(e.target.value);
      }}
    />
  );
};

const ShortNameInput = ({
  profile,
  role,
  setPayload,
  defaultInput,
  refreshKey,
}) => {
  // context
  const { profileItems } = useContext(ProfileContext);

  // state
  const [value, setValue] = useState(profile && profile.name);
  const [defaultVal, setDefaultVal] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState();

  // get all short names
  const allShortNames = [];
  profileItems.forEach((item) => {
    allShortNames.push(item.name.toLowerCase());
  });

  // for resetting default val if first- or last name changes
  useEffect(() => {
    setDefaultVal(defaultInput);
    if (defaultInput !== '') {
      setValue(defaultInput);
    }
  }, [defaultInput]);

  // effect of value - handle validation state
  useEffect(() => {
    if (value === '') {
      // empty input - trigger error
      setError(true);
      setHelperText('Ungültige Eingabe');
      setPayload((prevState) => {
        return { ...prevState, name: null };
      });
    } else if (
      (profile &&
        value !== profile.name &&
        allShortNames.includes(value.toLowerCase())) ||
      (!profile && allShortNames.includes(value && value.toLowerCase()))
    ) {
      // short name is taken - trigger error
      setError(true);
      setHelperText('Kürzel vergeben');
      setPayload((prevState) => {
        return { ...prevState, name: null };
      });
    } else {
      // valid input
      setError(false);
      setHelperText('');

      if (profile) {
        // editing menu
        if (profile.name !== value) {
          // value has changed
          setPayload((prevState) => {
            return { ...prevState, name: value };
          });
        } else {
          // value has not changed
          setPayload((prevState) => {
            return { ...prevState, name: null };
          });
        }
      } else {
        // adding menu
        if (typeof value !== 'undefined') {
          // value was entered
          setPayload((prevState) => {
            return { ...prevState, name: value };
          });
        } else {
          setPayload((prevState) => {
            return { ...prevState, name: null };
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <TextField
      key={refreshKey}
      spellCheck={false}
      required={true}
      defaultValue={profile ? profile.name : defaultVal}
      disabled={role === 'user' && profile.role !== 'Admin'}
      color='secondary'
      label='Kürzel'
      variant='standard'
      error={error}
      helperText={helperText}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const BirthdateInput = ({ profile, setPayload }) => {
  // state
  const [value, setValue] = useState(
    profile && profile.birthDate
      ? profile.birthDate.slice(0, profile.birthDate.lastIndexOf('T'))
      : ''
  );
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState();

  // effect of value - handle validation state
  useEffect(() => {
    if (value.length > 10) {
      // invalid input - trigger error
      setError(true);
      setHelperText('Ungültige Eingabe');
      setPayload((prevState) => {
        return { ...prevState, birthDate: null };
      });
    } else {
      // valid input - remove error
      setError(false);
      setHelperText('');

      if (profile) {
        // editing menu
        if (
          (profile.birthDate &&
            profile.birthDate.slice(0, profile.birthDate.lastIndexOf('T')) !==
              value) ||
          (profile.birthDate === null && value !== '')
        ) {
          // value has changed
          if (value === '') {
            setPayload((prevState) => {
              return { ...prevState, birthDate: '0001-01-01' };
            });
          } else {
            setPayload((prevState) => {
              return { ...prevState, birthDate: value };
            });
          }
        } else {
          // value has not changed
          setPayload((prevState) => {
            return { ...prevState, birthDate: null };
          });
        }
      } else {
        // adding menu
        if (value !== '') {
          // value was entered
          setPayload((prevState) => {
            return { ...prevState, birthDate: value };
          });
        } else {
          setPayload((prevState) => {
            return { ...prevState, birthDate: null };
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <form noValidate>
      <TextField
        className='birthday'
        type='date'
        defaultValue={
          profile &&
          profile.birthDate &&
          profile.birthDate.slice(0, profile.birthDate.lastIndexOf('T'))
        }
        color='secondary'
        label='Geburtstag'
        variant='standard'
        error={error}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setValue(e.target.value)}
      />
    </form>
  );
};

const PhoneNumberInput = ({ profile, setPayload }) => {
  // state
  const [value, setValue] = useState(
    profile && profile.phoneNumber ? profile.phoneNumber : ''
  );

  // effect of value - handle validation state
  useEffect(() => {
    if (profile) {
      // editing menu
      if (
        (profile.phoneNumber && profile.phoneNumber !== value) ||
        (profile.phoneNumber === null &&
          value !== '' &&
          typeof value !== 'undefined')
      ) {
        // value has changed
        if (typeof value === 'undefined') {
          setPayload((prevState) => {
            return { ...prevState, phoneNumber: 'null' };
          });
        } else {
          setPayload((prevState) => {
            return { ...prevState, phoneNumber: value };
          });
        }
      } else {
        // value has not changed
        setPayload((prevState) => {
          return { ...prevState, phoneNumber: null };
        });
      }
    } else {
      // adding menu
      if (typeof value !== 'undefined' && value !== '') {
        // value was entered
        setPayload((prevState) => {
          return { ...prevState, phoneNumber: value };
        });
      } else {
        setPayload((prevState) => {
          return { ...prevState, phoneNumber: null };
        });
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <PhoneInput
      className='phoneNumber'
      defaultCountry='DE'
      color='secondary'
      value={value}
      onChange={(value) => setValue(value)}
    />
  );
};

const LocationSelection = ({ profile, setPayload }) => {
  // context
  const { locationItems } = useContext(ProfileContext);

  return (
    <SelectionInput
      label='Standort'
      defaultValue={profile ? profile.defaultLocation : ''}
      param='defaultLocationId'
      items={locationItems}
      setPayload={setPayload}
    />
  );
};

const SubmitButton = ({ type, onSubmit, setRefreshKey }) => {
  // state
  const [disabled, setDisabled] = useState(false);

  // init 'disable' state
  useEffect(() => {
    checkInputFields();
  }, []);

  // effect of 'disabled'
  useEffect(() => {
    document.addEventListener('input', checkInputFields);
    // for MUI select elements (custom event)
    document.addEventListener('inputChanged', checkInputFields);
    return () => {
      document.removeEventListener('input', checkInputFields);
      document.removeEventListener('inputChanged', checkInputFields);
    };
  }, [disabled]);

  // validate input fields
  const checkInputFields = (e) => {
    let requiredError = false;

    setTimeout(() => {
      // validate if all required inputs are filled
      document.querySelectorAll('.required input').forEach((elem) => {
        if (elem.value === '') {
          requiredError = true;
        }
      });
      // validate if there is no error
      if (
        document.querySelectorAll('.settings-form p.Mui-error').length > 0 ||
        requiredError
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }, 250);
  };

  // handle actions after click
  const handleClick = () => {
    if (type === 'add') {
      // clear input fields
      setRefreshKey((prevKey) => prevKey + 1);
    } else if (type === 'delete') {
      // trigger back button
    }
    setDisabled(true);
  };

  return (
    <div className='submitSettings'>
      <Button
        variant='contained'
        color='secondary'
        disabled={disabled}
        onClick={() => {
          onSubmit();
          handleClick();
        }}
      >
        {type === 'delete' && 'Profil Löschen'}
        {type === 'add' && 'Profil Anlegen'}
        {type === 'edit' && 'Speichern'}
      </Button>
    </div>
  );
};

/* ADMIN COMPONENTS */

const TeamSelection = ({ profile, setPayload }) => {
  // context
  const { teamItems } = useContext(ProfileContext);

  return (
    <SelectionInput
      label='Team'
      defaultValue={profile ? profile.team : ''}
      param='teamId'
      items={teamItems}
      setPayload={setPayload}
    />
  );
};

const RoleSelection = ({ profile, setPayload }) => {
  // context
  const { roleItems } = useContext(ProfileContext);

  return (
    <SelectionInput
      label='Rolle'
      defaultValue={profile ? profile.role : ''}
      param='roleId'
      items={roleItems}
      setPayload={setPayload}
    />
  );
};

const PasswordInput = ({ profile, setPayload }) => {
  // state
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value !== '') {
      setPayload((prevState) => {
        return { ...prevState, passwordHash: value };
      });
    } else {
      setPayload((prevState) => {
        return { ...prevState, passwordHash: null };
      });
    }
  }, [value, setPayload]);

  return (
    <div className='form-row-Wrapper'>
      <TextField
        className='password-input'
        defaultValue={profile && profile.passwordHash}
        color='secondary'
        label='Passwort zurücksetzen'
        variant='outlined'
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const DeleteEmployeeForm = ({ profile }) => {
  return (
    <div className='delete-item profile-item'>
      <Profile profile={profile} />
      <p className='profile-name'>{`${profile.firstName} ${profile.lastName}`}</p>
    </div>
  );
};

/* USER COMPONENTS */

const ImageButton = ({ setImageMenu }) => {
  return (
    <div className='add-image'>
      <Button
        variant='outlined'
        color='secondary'
        component='span'
        onClick={() => setImageMenu(true)}
        startIcon={<CropOriginalIcon />}
      >
        Bild
      </Button>
    </div>
  );
};

const DescriptionButton = ({ setDescriptionMenu }) => {
  return (
    <div className='add-description'>
      <Button
        variant='outlined'
        color='secondary'
        component='span'
        onClick={() => setDescriptionMenu(true)}
        startIcon={<DescriptionIcon />}
      >
        Beschreibung
      </Button>
    </div>
  );
};
