import React, { useContext, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import ErrorPage from './ErrorPage';
import MenuArea from './MenuArea';
import TopMenuArea from './TopMenuArea';
import ProfileArea from './ProfileArea';
import ProfileInformation from '../profile/ProfileInformation';
import AlertSnackbar from '../shared/AlertSnackbar';
import LoadingAnimation from '../shared/LoadingAnimation';
import ThemeContext from '../../context/theme/themeContext';
import LayoutContext from '../../context/layout/layoutContext';
import ProfileContext from '../../context/profile/profileContext';
import ProfileWithMenu from '../profile/ProfileWithMenu';
import AdminSettings from '../settings/admin/AdminSettings';
import UserSettings from '../settings/user/UserSettings';
import RefreshHint from '../layout/RefreshHint';

const MainScreen = () => {
  // context
  const { themeLight } = useContext(ThemeContext);
  const {
    getAllData,
    loading,
    user,
    updateProfileStatusShortCut,
    sessionID,
  } = useContext(ProfileContext);
  const {
    settingsMenu,
    alert,
    setAlert,
    statusMenuUser,
    setStatusMenuUser,
    profileInformationUser,
    backgroundOpacity,
    locationFilter,
    refreshPageHint,
    setSidebarHided,
    sidebarHided,
    // errorPage,
  } = useContext(LayoutContext);

  // fetch inital data
  useEffect(() => {
    getAllData();
    localStorage.setItem('sessionID', sessionID);
    // eslint-disable-next-line
  }, []);

  // handle keydown event
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [user, backgroundOpacity]);

  // handle submit on main screen
  const handleKeyDown = (e) => {
    console.log(backgroundOpacity);
    if (!backgroundOpacity && e.keyCode === 13) {
      updateProfileStatusShortCut(user);
    }
  };

  // styling
  const theme = themeLight ? 'light' : 'dark';
  const errorPage = false;

  let statusMenuStyle;
  // necessary for letting status menu slide in slowly
  if (statusMenuUser) {
    statusMenuStyle = {
      transform: 'scale(1)',
      opacity: 1,
    };
  } else {
    statusMenuStyle = {
      transform: 'scale(0)',
      opacity: 0,
    };
  }

  if (!loading && !errorPage) {
    return (
      <main
        onKeyDown={(e) => handleKeyDown(e)}
        className={`content ${theme}${user.role === 'Device' ? ' device' : ''}`}
      >
        {user.role !== 'Device' ? (
          <ProfileArea locationFilter={locationFilter}>
            {profileInformationUser && (
              <ProfileInformation user={profileInformationUser} />
            )}
            {settingsMenu && settingsMenu === 'admin' && <AdminSettings />}
            {settingsMenu && settingsMenu === 'user' && <UserSettings />}
            {user.role !== 'User' && (
              <ProfileWithMenu
                restricted={true}
                profile={statusMenuUser}
                menuOverlayShown={true}
                removeStatusMenu={() => setStatusMenuUser(false)}
                style={statusMenuStyle}
              />
            )}
          </ProfileArea>
        ) : (
          <TopMenuArea />
        )}
        {user.role !== 'Device' ? (
          <MenuArea hided={sidebarHided}>
            {window.innerWidth <= 1000 && sidebarHided !== null && (
              <div
                onClick={() => setSidebarHided(!sidebarHided)}
                className='sidebar-trigger'
              >
                <Icon>{`${sidebarHided ? 'arrow_left' : 'arrow_right'}`}</Icon>
              </div>
            )}
          </MenuArea>
        ) : (
          <ProfileArea locationFilter={locationFilter}>
            {user.role !== 'User' && (
              <ProfileWithMenu
                restricted={true}
                profile={statusMenuUser}
                menuOverlayShown={true}
                removeStatusMenu={() => setStatusMenuUser(false)}
                style={statusMenuStyle}
              />
            )}
          </ProfileArea>
        )}
        {backgroundOpacity && (
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,.6)',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 2,
              height: '100vh',
              position: 'fixed',
            }}
          ></div>
        )}

        {alert && (
          <AlertSnackbar alert={alert} handleClose={() => setAlert(false)} />
        )}
        {refreshPageHint && <RefreshHint />}
      </main>
    );
  } else {
    if (errorPage && !loading) {
      return <ErrorPage type={errorPage} />;
    } else {
      return <LoadingAnimation />;
    }
  }
};

export default MainScreen;
