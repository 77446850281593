import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { useLongPress } from '../../utils/helpers/useLongPress';
import ProfileContext from '../../context/profile/profileContext';
import LayoutContext from '../../context/layout/layoutContext';

const Profile = ({
  profile,
  handleClick,
  handleLongPress,
  menuOverlayShown,
  shortProfile,
  withMenu,
  setMenuOverlay,
}) => {
  // context
  const { config } = useContext(ProfileContext);
  const { animatedUsers } = useContext(LayoutContext);

  // styles
  const profileImage = `https://api.wayne.e-dynamics.de${profile.filePath}`;
  const statusName = profile.status.toLowerCase();
  const configObject = config[statusName];
  const available = configObject['available'];
  const badge = configObject['badgeShown'] && configObject['badge'];
  const borderColor = configObject['borderColor'];
  const className = `profile${withMenu ? ' withMenu' : ''}${
    available || shortProfile ? ' available' : ''
  }${menuOverlayShown ? ' menu-shown' : ''}`;

  let color = configObject['color'];
  let isAnimated = false;
  // set color when there is a status transition
  if (animatedUsers.length > 0) {
    let status_name;
    let config_object;
    animatedUsers.forEach((user) => {
      if (user.userId === profile.id) {
        isAnimated = true;
        status_name = user.status;
        config_object = config[status_name];
        color = config_object['borderColor'];
      }
    });
  }

  // handle short press
  const shortPressHandler = () => {
    if (typeof handleClick === 'function') {
      handleClick(profile);
    }
  };

  // handle long press
  const longPressHandler = (e) => {
    if (typeof handleLongPress === 'function') {
      handleLongPress(profile);
    }
  };
  // longpress hook
  const longPressTrigger = useLongPress(longPressHandler, 500);

  return (
    <div
      {...longPressTrigger}
      onClick={() => shortPressHandler()}
      onMouseEnter={setMenuOverlay}
      className={isAnimated ? className + ' animated' : className}
      style={{ borderColor, color }}
    >
      <div
        style={{
          backgroundImage: `url(${profileImage}`,
        }}
        className={`profile-image${
          available || shortProfile ? ' available' : ''
        }`}
      ></div>
      {badge && (!withMenu || !menuOverlayShown) && (
        <i className={`statusBadge ${statusName}`}>
          <Icon>{badge}</Icon>
        </i>
      )}
    </div>
  );
};

export default Profile;

// propTypes
Profile.propTypes = {
  profile: PropTypes.object.isRequired,
};
