import React, { useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const AdminBottomNav = ({ handleMenuSwitch, type }) => {
  // state
  const [value, setValue] = useState('Hinzufügen');

  // handle menu switch
  const handleChange = (event, newValue) => {
    // 'event' is required by MUI
    setValue(newValue);
    handleMenuSwitch(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className='bottomNav'
    >
      <BottomNavigationAction
        label='Hinzufügen'
        value='Hinzufügen'
        icon={<AddIcon />}
      />
      {type !== 'status' && (
        <BottomNavigationAction
          label='Bearbeiten'
          value='Bearbeiten'
          icon={<EditIcon />}
        />
      )}
      <BottomNavigationAction
        label='Löschen'
        value='Löschen'
        icon={<DeleteIcon />}
      />
    </BottomNavigation>
  );
};

export default AdminBottomNav;
