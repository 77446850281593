import React from 'react';
import { useTimer } from '../../utils/helpers/useTimer';

const DateWidget = () => {
  const [timeString, dateString] = useTimer();

  return (
    <div className='date-widget'>
      <h1>{timeString}</h1>
      <p className='dateString'>{dateString}</p>
    </div>
  );
};

export default DateWidget;
