import React, { useContext, useEffect } from 'react';
import ProfileContext from '../../context/profile/profileContext';
import Icon from '@material-ui/core/Icon';
import { calculateStatusItemsPositions } from '../../utils/helpers/calculateStatusItemsPositions';

const MenuOverlay = ({
  profile,
  removeMenuOverlay,
  menuOverlayShown,
  removeStatusMenu,
  restricted, // if true -> admin/device menu
}) => {
  // context
  const { updateProfileStatus, statusItems, config } = useContext(
    ProfileContext
  );

  // calculate position of menu items
  useEffect(() => {
    if (menuOverlayShown) {
      calculateStatusItemsPositions();
    }
  }, [menuOverlayShown]);

  // handle click on status item
  const handleSelection = (userId, statusId) => {
    if (typeof removeMenuOverlay === 'function') {
      // own profile
      removeMenuOverlay();
    }
    if (typeof removeStatusMenu === 'function') {
      // other profile
      removeStatusMenu();
    }
    updateProfileStatus(userId, statusId);
  };

  const renderedStatus = statusItems.map(
    (item, index) =>
      // check whether restricted (admin, device) or user menu
      typeof config[item.name.toLowerCase()] !== 'undefined' &&
      ((typeof restricted === 'undefined' &&
        config[item.name.toLowerCase()]['selectableUser']) ||
        config[item.name.toLowerCase()]['selectableAdmin']) && (
        <a
          key={index}
          style={{ color: config[item.name.toLowerCase()]['color'] }}
          className={`${profile.status === item.name ? 'selected' : ''} flex`}
          onClick={() => handleSelection(profile.id, item.id)}
        >
          <Icon>{config[item.name.toLowerCase()]['badge']}</Icon>
        </a>
      )
  );

  return (
    <div
      style={menuOverlayShown ? styleVisible : styleInvisible}
      className={`menuOverlay ${menuOverlayShown && 'shown'}`}
      onMouseLeave={removeMenuOverlay}
    >
      {renderedStatus}
    </div>
  );
};

// styles
const styleVisible = {
  transform: 'scale(1)',
  opacity: 1,
};

const styleInvisible = {
  transform: 'scale(0.6)',
  opacity: 0,
};

export default MenuOverlay;
