export const filterByLocation = (profileItems, locationItems) => {
  // copy by value
  const profile_items = [...profileItems];

  let filtered_profiles = {};

  locationItems.forEach((item) => {
    filtered_profiles[item.name] = [];
  });

  // put profile items in corresponding location
  profile_items.forEach((profile) => {
    if (typeof filtered_profiles[profile.location] === 'undefined') {
      filtered_profiles[profile.location] = [];
    }
    filtered_profiles[profile.location].push(profile);
  });

  return filtered_profiles;
};

export const sortAlphabetically = (items) => {
  // copy by value
  let _items = [...items];

  _items.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  return _items;
};

export const sortByAvailability = (profileItems, config) => {
  // copy by value
  let profile_items = [...profileItems];
  let status_config = Object.assign({}, config);

  let filtered_status = Object.entries(status_config);
  let filtered_profiles = [];

  // sort status config by availability
  filtered_status.sort(function ([keyA, valA], [keyB, valB]) {
    return valA.available === valB.available ? 0 : valA.available ? -1 : 1;
  });

  // loop through status config and put profiles in result
  Object.values(filtered_status).forEach(([status, values]) => {
    profile_items.forEach((profile) => {
      if (status === profile.status.toLowerCase()) {
        filtered_profiles.push(profile);
      }
    });
  });

  return filtered_profiles;
};
