import { postRequest, deleteRequest } from '../templates';

const endpoint = 'Image';

export const addImage = async (id, data) => {
  /* postRequest(`${endpoint}/${id}`, data).then((response) => {
    return response;
  });*/
  return await postRequest(`${endpoint}/${id}`, data);
};

export const deleteImage = async (id) => {
  /* deleteRequest(`${endpoint}/${id}`).then((response) => {
    return response;
  });*/
  return await deleteRequest(`${endpoint}/${id}`);
};
