import React, { useReducer, useContext, useEffect, useState } from 'react';
import ProfileContext from './profileContext';
import ProfileReducer from './profileReducer';
import LayoutContext from '../../context/layout/layoutContext';
import {
  fetchAllData,
  fetchProfileChanges,
} from '../../utils/api/fetchAllData';
import { getEntry, getEntryByName } from '../../utils/api/getEntry';
import {
  addUser,
  updateUser,
  deleteUser,
} from '../../utils/api/endpoints/user';
import { addImage, deleteImage } from '../../utils/api/endpoints/image';
import {
  addStatus,
  updateStatus,
  deleteStatus,
} from '../../utils/api/endpoints/status';
import { postAssetFunctions } from '../../utils/api/templates';
import { GET_ALL_DATA, SET_LOADING } from './types';
import { v4 as uuidv4 } from 'uuid';
import { isGoogleChrome } from '../../utils/helpers/isGoogleChrome';

const ProfileState = (props) => {
  const initialState = {
    // data from API
    user: {},
    profileItems: [],
    locationItems: [],
    statusItems: [],
    teamItems: [],
    roleItems: [],
    // config.json
    config: {},
    // fetching state
    loading: true,
    error: false,
    sessionID: uuidv4(),
  };

  const [state, dispatch] = useReducer(ProfileReducer, initialState);
  const [fetchNewBool, setFetchNewBool] = useState(false);
  const {
    setAlert,
    setErrorPage,
    pushAnimatedUser,
    removeAnimatedUser,
    setRefreshPageHint,
  } = useContext(LayoutContext);

  /* DATA FETCH AND SYNC */

  // sync profile data every 10 seconds
  useEffect(() => {
    if (Object.keys(state.config).length !== 0) {
      const interval = setInterval(() => {
        if (fetchNewBool) {
          syncProfileData();
        }
      }, state.config['refreshRateInSeconds'].profileData * 1000);
      return () => clearInterval(interval);
    }
  }, [fetchNewBool, state]);

  // sync general data every 3 hours
  useEffect(() => {
    if (Object.keys(state.config).length !== 0) {
      const interval = setInterval(() => {
        if (fetchNewBool) {
          syncGeneralData();
        }
      }, state.config.refreshRateInSeconds.generalData * 1000); // every 3 hours
      return () => clearInterval(interval);
    }
  }, [fetchNewBool, state]);

  // initial data fetch
  const getAllData = async () => {
    setLoading();

    fetchAllData().then((res) => {
      if (res) {
        dispatch({ type: GET_ALL_DATA, payload: res });
        setFetchNewBool(true);
        window.WAYNe_data = res;
      } else {
        setErrorPage('fetchingError'); // TBD
      }
    });
  };

  // sync just profile data
  const syncProfileData = () => {
    const sessionID = localStorage.getItem('sessionID');

    if (sessionID !== state.sessionID) {
      setRefreshPageHint(true);
    } else {
      fetchProfileChanges(state).then((res) => {
        if (res) {
          // when user status changes to 'anwesend' -> push notification
          res.profileItems.forEach((item) => {
            if (item.statusId === 1 || item.statusId === 3) {
              state.profileItems.forEach((prevItem) => {
                if (
                  item.id === prevItem.id &&
                  prevItem.statusId !== 1 &&
                  prevItem.statusId !== 3
                ) {
                  sendNotification(item);
                }
              });
            }
          });
          dispatch({ type: GET_ALL_DATA, payload: res });
          window.WAYNe_data = res;
        } else {
          // do nothing
          console.log('ERROR');
        }
        //removeAnimatedUser();
      });
    }
  };

  // sync all app data
  const syncGeneralData = () => {
    fetchAllData().then((res) => {
      if (res) {
        dispatch({ type: GET_ALL_DATA, payload: res });
        window.WAYNe_data = res;
      } else {
        // do nothing
      }
    });
  };

  const setLoading = () => dispatch({ type: SET_LOADING });

  /* UPDATE STATUS AND LOCATION OF USER */

  const updateProfileStatus = (userId, statusId) => {
    // init relevant values
    const user =
      userId === state.user.id
        ? state.user
        : getEntry(state.profileItems, userId);
    const status = getEntry(state.statusItems, statusId).name;
    const status_offsite = state.config[status.toLowerCase()].offsite;

    let locationId = user.locationId;
    let location = user.location;

    let newUserData = {
      id: userId,
      statusId: statusId,
    };

    // trigger loading animation for this profile
    pushAnimatedUser(userId, status.toLowerCase());

    // set locationId depending on status
    if (!status_offsite) {
      if (
        (user.status === 'Homeoffice' ||
          user.status === 'Unterwegs' ||
          user.status === 'Anwesend') &&
        status === 'Pause'
      ) {
        // do nothing
      } else {
        locationId = user.defaultLocationId;
      }
      // if device and not offsite status set always default location (e.g. Aachen or Berlin)
      locationId =
        state.user.role === 'Device'
          ? state.user.defaultLocationId
          : locationId;
    } else {
      locationId = 0;
    }

    // set final location values
    newUserData.locationId = locationId;
    location = getEntry(state.locationItems, locationId);
    location = location === false ? 'Offsite' : location.name;

    console.log(newUserData);

    updateUser(newUserData).then((res) => {
      if (res) {
        // success - change data locally (for performance reasons)
        let newState = state;

        if (userId === state.user.id) {
          // change user profile
          newState.user.statusId = statusId;
          newState.user.status = status;
          newState.user.locationId = locationId;
          newState.user.location = location;
        } else {
          // change other profile
          for (let i = 0; i < state.profileItems.length; i++) {
            if (state.profileItems[i].id === userId) {
              newState.profileItems[i].statusId = statusId;
              newState.profileItems[i].status = status;
              newState.profileItems[i].location = location;
              newState.profileItems[i].locationId = locationId;
            }
          }
        }
        //syncProfileData();
      } else {
        // error
        setAlert({ text: 'statusChangeFailure', type: 'error' });
      }
      removeAnimatedUser();
    });
  };

  // shortcut for status change when role is 'Device' and for own profile
  const updateProfileStatusShortCut = (user) => {
    const status = getEntry(state.statusItems, user.statusId).name;
    const statusName = status.toLowerCase();
    let newStatusName;

    if (statusName === 'anwesend' || statusName === 'homeoffice') {
      const currentTime = new Date();
      const leftBorder = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        11,
        30
      );
      const rightBorder = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        14,
        0
      );

      if (
        leftBorder.getTime() <= currentTime.getTime() &&
        currentTime.getTime() <= rightBorder.getTime()
      ) {
        newStatusName = 'pause';
      } else {
        newStatusName = 'abwesend';
      }
    } else {
      newStatusName = 'anwesend';
    }
    let newStatusItem = getEntryByName(state.statusItems, newStatusName);
    if (newStatusItem !== false) {
      updateProfileStatus(user.id, newStatusItem.id);
    }
  };

  const updateProfileLocation = (payload) => {
    let userId = payload.id;
    let locationId = payload.locationId;
    let location =
      locationId !== null
        ? getEntry(state.locationItems, locationId).name
        : 'Offsite';

    updateUser(payload).then((res) => {
      if (res) {
        /* // success - change data locally
        let newState = Object.assign({}, state);

        if (userId === state.user.id) {
          // change user profile
          newState.user.locationId = locationId;
          newState.user.location = location;
        } else {
          // change other profile
          for (let i = 0; i < state.profileItems.length; i++) {
            if (state.profileItems[i].id === userId) {
              newState.profileItems[i].locationId = locationId;
              newState.profileItems[i].location = location;
            }
          }
        }*/

        //dispatch({ type: GET_ALL_DATA, payload: newState });
        syncProfileData();
      } else {
        setAlert({ text: 'locationChangeFailure', type: 'error' });
      }
    });
  };

  /* ADD, DELETE & UPDATE PROFILE */

  const addProfile = (payload) => {
    // data request
    addUser(payload).then((res) => {
      if (res) {
        setAlert({ text: 'profile_add', type: 'success' });
        syncGeneralData();
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const updateProfile = (payload) => {
    // data request
    updateUser(payload).then((res) => {
      if (res) {
        syncProfileData();
        setAlert({ text: 'changeSuccess', type: 'success' });
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const deleteProfile = (userId) => {
    deleteUser(userId).then((res) => {
      if (res) {
        setAlert({ text: 'profile_delete', type: 'success' });
        syncGeneralData();
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const addProfileImage = (userId, data) => {
    addImage(userId, data).then((res) => {
      if (res !== false) {
        window.location.reload(true);
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const deleteProfileImage = (userId) => {
    deleteImage(userId).then((res) => {
      if (res !== false) {
        window.location.reload(true);
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };
  /* ADD, DELETE & UPDATE STATUS ITEMS */

  const addStatusItem = (payload) => {
    // data request
    addStatus(payload).then((res) => {
      if (res) {
        setAlert({ text: 'status_add', type: 'success' });
        syncGeneralData();
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const updateStatusItem = (payload) => {
    // data request
    updateStatus(payload).then((res) => {
      if (res) {
        setAlert({ text: 'changeSuccess', type: 'success' });
        syncGeneralData();
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const deleteStatusItem = (statusId) => {
    // data request
    deleteStatus(statusId).then((res) => {
      if (res) {
        setAlert({ text: 'status_delete', type: 'success' });
        syncGeneralData();
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  // TODO: ADD, DELETE & UPDATE LOCATION ITEMS
  // TODO: ADD, DELETE & UPDATE TEAM ITEMS

  /* SET CONFIG FILE */
  const setConfig = (funcName, user, configData) => {
    postAssetFunctions(funcName, user, configData).then((res) => {
      if (res !== false) {
        syncGeneralData();
        setAlert({ text: 'changeSuccess', type: 'success' });
      } else {
        setAlert({ text: 'changeFailure', type: 'error' });
      }
    });
  };

  const sendNotification = (user) => {
    if (
      typeof Notification !== 'undefined' &&
      Notification.permission !== 'denied' &&
      document.hidden
    ) {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const title = `${user.firstName} ${user.lastName} ist ${
            user.statusId === 1 ? 'anwesend' : 'im Homeoffice'
          }!`;
          const icon = `https://api.wayne.e-dynamics.de${user.filePath}`;

          var notification = new Notification(title, {
            icon: icon,
          });

          // go to active WAYNe Tab if notification is clicked
          notification.addEventListener('click', () => {
            window.focus();
          });
        }
      });
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        user: state.user,
        profileItems: state.profileItems,
        locationItems: state.locationItems,
        statusItems: state.statusItems,
        roleItems: state.roleItems,
        teamItems: state.teamItems,
        config: state.config,
        loading: state.loading,
        error: state.error,
        sessionID: state.sessionID,
        getAllData,
        syncGeneralData,
        updateProfileStatus,
        updateProfileLocation,
        addProfile,
        updateProfile,
        deleteProfile,
        addProfileImage,
        deleteProfileImage,
        addStatusItem,
        updateStatusItem,
        deleteStatusItem,
        setConfig,
        updateProfileStatusShortCut,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileState;
