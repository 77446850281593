import axios from 'axios';

const request_url = 'https://api.wayne.e-dynamics.de/api';
//const wayne_dev_url = 'https://wayne-dev.e-dynamics.net';
//const wayne_dev_url = 'http://localhost:80';
const wayne_dev_url = '';

// POST (without header - not authenticated)
export const initPostRequest = async (endpoint, data) => {
  return axios
    .post(`${request_url}/${endpoint}`, data)
    .then((res) => {
      return res.data.response;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

// POST (with header - authenticated)
export const postRequest = async (endpoint, data) => {
  const headers = { headers: { Authorization: getToken() } };

  // change content type for image endpoint
  if (endpoint.includes('Image')) {
    headers.headers['Content-Type'] = 'multipart/form-data';
  }

  console.log(headers);

  return axios
    .post(`${request_url}/${endpoint}`, data, headers)
    .then((res) => {
      if (res.data.response.token) {
        localStorage.setItem('token', res.data.response.token);
      }
      return res.data.response;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

// GET
export const getRequest = async (endpoint) => {
  const headers = { headers: { Authorization: getToken() } };

  return axios
    .get(`${request_url}/${endpoint}`, headers)
    .then((res) => {
      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
      }
      if (res.data.timestamp) {
        localStorage.setItem('timestamp', res.data.timestamp);
      }
      return res.data.response;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

// PUT
export const putRequest = async (endpoint, data) => {
  const headers = { headers: { Authorization: getToken() } };

  return axios
    .put(`${request_url}/${endpoint}`, data, headers)
    .then((res) => {
      if (res.data.response.token) {
        localStorage.setItem('token', res.data.response.token);
      }
      return res.data.response;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

// DELETE
export const deleteRequest = async (endpoint) => {
  const headers = { headers: { Authorization: getToken() } };

  return axios
    .delete(`${request_url}/${endpoint}`, headers)
    .then((res) => {
      return res.data.response;
    })
    .catch((error) => {
      console.error(error);
    });
};

// GET TOKEN HELPER
const getToken = () => {
  let token = localStorage.getItem('token');
  if (token !== null) {
    return token;
  } else {
    return false;
  }
};

// POST ASSET FUNCTIONS (Calendar, Config)
export const postAssetFunctions = (funcName, user, configData) => {
  var bodyFormData = new FormData();
  bodyFormData.set('user', user);
  bodyFormData.set('token', getToken());
  bodyFormData.set('function', funcName);
  if (funcName === 'setConfig' && configData) {
    bodyFormData.set('config', configData);
  }

  return axios({
    method: 'post',
    url: `${wayne_dev_url}/assetFunctions.php`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function (response) {
      //handle success
      return response.data;
    })
    .catch((error) => {
      //handle error
      console.error(error);
      return false;
    });
};
