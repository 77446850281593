import React, { useState, useContext, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import AdminBottomNav from './AdminBottomNav';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProfileContext from '../../../context/profile/profileContext';
import { sortAlphabetically } from '../../../utils/helpers/filterProfiles';

const StatusSettings = () => {
  // state
  const [refreshKey, setRefreshKey] = useState(0);
  const [settingsMenu, setSettingsMenu] = useState(
    <StatusForm setRefreshKey={setRefreshKey} />
  );

  // set settings menu
  const handleMenuSwitch = (value) => {
    switch (value) {
      case 'Hinzufügen':
        setSettingsMenu(<StatusForm setRefreshKey={setRefreshKey} />);
        break;
      case 'Löschen':
        setSettingsMenu(<StatusSelection />);
        break;
      default:
      // do nothing
    }
  };

  return (
    <div key={refreshKey} className='settings-wrapper'>
      {settingsMenu}
      <AdminBottomNav type={'status'} handleMenuSwitch={handleMenuSwitch} />
    </div>
  );
};

export default StatusSettings;

// ADD & EDIT STATUS FORM
const StatusForm = ({ handleBackButton, setRefreshKey }) => {
  // context
  const { statusItems, addStatusItem, updateStatusItem, config } = useContext(
    ProfileContext
  );

  // state
  const [statusName, setStatusName] = useState();
  const [buttonDisabled, disableButton] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState();
  const [hint, setHint] = useState('success');

  // effect
  useEffect(() => {
    // get all existing status names
    let statusNames = [];
    Object.values(statusItems).forEach((item) => {
      statusNames.push(item.name.toLowerCase());
    });

    if (statusName === '') {
      // empty input
      disableButton(true);
      setError(true);
      setHelperText('Ungültige Eingabe');
      setHint('warning');
    } else if (typeof statusName === 'undefined') {
      // adding menu - empty input
      disableButton(true);
      setHint('warning');
    } else if (statusNames.includes(statusName.toLowerCase())) {
      // status name already taken
      disableButton(true);
      setError(true);
      setHelperText('Status existiert bereits');
      setHint('warning');
    } else {
      // valid input
      setError(false);
      setHelperText('');
      if (Object.keys(config).includes(statusName.toLowerCase())) {
        // valid input and styling is set
        disableButton(false);
        setHint('success');
      } else {
        disableButton(true);
        setHint('warning');
      }
    }
  }, [statusName, statusItems]);

  // handle submit click
  const handleSubmit = () => {
    addStatusItem({ name: statusName });
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div className='settings-form'>
      <div className='status-input'>
        <TextField
          error={error}
          helperText={helperText}
          color='secondary'
          label='Status'
          variant='standard'
          onChange={(e) => setStatusName(e.target.value)}
        />
      </div>
      <div className={`status-hint ${hint}`}>
        {hint === 'warning' && (
          <p>
            Um einen neuen Status anlegen zu können, ergänze <u>zunächst</u>{' '}
            essentielle Styling-Informationen in <strong>config.json</strong>{' '}
            und speichere diese.
          </p>
        )}
        {hint === 'success' && (
          <p>Styling-Informationen wurden erfolgreich hinterlegt.</p>
        )}
      </div>
      {handleBackButton && (
        <ArrowBackIosIcon className='back-button' onClick={handleBackButton} />
      )}
      <div className='submitSettings status'>
        <Button
          disabled={buttonDisabled}
          onClick={() => handleSubmit()}
          variant='contained'
          color='secondary'
          component='span'
        >
          Status anlegen
        </Button>
      </div>
    </div>
  );
};

// DELETE STATUS FORM
const DeleteStatusForm = ({ status, handleBackButton }) => {
  // state
  const [disabled, setDisabled] = useState(false);
  // context
  const { deleteStatusItem, config } = useContext(ProfileContext);

  const handleClick = (statusID) => {
    setDisabled(true);
    deleteStatusItem(statusID);
    handleBackButton();
  };

  // styling information
  const className = status.name.toLowerCase();
  const iconName =
    typeof config[status.name.toLowerCase()] !== 'undefined'
      ? config[status.name.toLowerCase()]['badge']
      : '';
  const color =
    typeof config[status.name.toLowerCase()] !== 'undefined'
      ? config[status.name.toLowerCase()]['color']
      : '';
  const borderColor =
    typeof config[status.name.toLowerCase()] !== 'undefined'
      ? config[status.name.toLowerCase()]['borderColor']
      : '';

  return (
    <div className='settings-form'>
      <div style={{ color }} className='delete-item status-item'>
        <div className='icon-wrapper'>
          <i className={`statusBadge ${className}`}>
            <Icon>{iconName}</Icon>
          </i>
        </div>
        <div className='status-name'>
          <p>{status.name}</p>
        </div>
      </div>
      <ArrowBackIosIcon className='back-button' onClick={handleBackButton} />
      <div className='submitSettings delete'>
        <Button
          onClick={() => handleClick(status.id)}
          variant='contained'
          color='secondary'
          component='span'
          disabled={disabled}
        >
          Status löschen
        </Button>
      </div>
    </div>
  );
};

// STATUS SELECTION (DELETE)
const StatusSelection = () => {
  // context
  const { statusItems } = useContext(ProfileContext);
  const items = sortAlphabetically(statusItems);

  // state
  const [selectedItem, setItem] = useState();

  // handle back button click
  const handleBackButton = () => {
    setItem(null);
  };

  return !selectedItem ? (
    <div className='selection-row'>
      {items.map((status, index) => {
        return (
          <Button
            key={index}
            className='selection-item'
            variant='outlined'
            color='secondary'
            component='span'
            onClick={() => setItem(status)}
          >
            {status.name}
          </Button>
        );
      })}
    </div>
  ) : (
    <DeleteStatusForm
      handleBackButton={handleBackButton}
      status={selectedItem}
    />
  );
};
