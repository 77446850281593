import React, { useState, useContext, useEffect } from 'react';
import AdminBottomNav from './AdminBottomNav';
import EmployeeForm from '../EmployeeForm';
import Button from '@material-ui/core/Button';
import ProfileContext from '../../../context/profile/profileContext';
import { sortAlphabetically } from '../../../utils/helpers/filterProfiles';

const EmployeeSettings = () => {
  // state
  const [settingsMenu, setSettingsMenu] = useState(<AddEmployeeForm />);

  // set settings menu
  const handleMenuSwitch = (value) => {
    switch (value) {
      case 'Hinzufügen':
        setSettingsMenu(<AddEmployeeForm />);
        break;
      case 'Bearbeiten':
        setSettingsMenu(<EmployeeSelection type='edit' />);
        break;
      case 'Löschen':
        setSettingsMenu(<EmployeeSelection type='delete' />);
        break;
      default:
      // do nothing
    }
  };

  return (
    <div className='settings-wrapper'>
      {settingsMenu}
      <AdminBottomNav handleMenuSwitch={handleMenuSwitch} />
    </div>
  );
};

export default EmployeeSettings;

// ADD EMPLOYEE
const AddEmployeeForm = () => {
  // state
  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <EmployeeForm key={refreshKey} type='add' setRefreshKey={setRefreshKey} />
  );
};

// EMPLOYEE SELECTION (EDIT, DELETE)
const EmployeeSelection = ({ type }) => {
  // context
  const { profileItems, user } = useContext(ProfileContext);
  const items = sortAlphabetically(profileItems);

  // state
  const [selectedItem, setItem] = useState();

  // effect - switch menu: reset selected item
  useEffect(() => {
    setItem(null);
  }, [type]);

  // handle back button click
  const handleBackButton = () => {
    setItem(null);
  };

  return !selectedItem ? (
    <div className='selection-row'>
      {items.map(
        (profile, index) =>
          // make own profile not selectable for deleting
          ((profile.id === user.id && type !== 'delete') ||
            profile.id !== user.id) && (
            <Button
              key={index}
              className='selection-item'
              variant='outlined'
              color='secondary'
              component='span'
              onClick={() => setItem(profile)}
            >
              {`${profile.firstName} ${profile.lastName}`}
            </Button>
          )
      )}
    </div>
  ) : (
    <EmployeeForm
      type={type}
      handleBackButton={handleBackButton}
      profile={selectedItem}
    />
  );
};
