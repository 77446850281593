import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getEntryByName } from '../../utils/api/getEntry';

const SelectionInput = ({ label, defaultValue, param, items, setPayload }) => {
  // state
  const [value, setValue] = useState(defaultValue);

  // handle change of input value
  const handleChange = (e) => {
    // create custom event for input change (necessary for submit button)
    var event = new Event('inputChanged');
    document.dispatchEvent(event);

    // set new value
    setValue(e.target.value);
  };

  // effect of value - handle validation state
  useEffect(() => {
    if (value !== '') {
      // valid input
      if (defaultValue !== value || defaultValue === '') {
        // value has changed
        if (param === 'defaultLocationId') {
          // set defaultLocation and location
          setPayload((prevState) => {
            return {
              ...prevState,
              [param]:
                value === 'Offsite' ? 0 : getEntryByName(items, value).id,
            };
          });
          setPayload((prevState) => {
            return {
              ...prevState,
              locationId:
                value === 'Offsite' ? 0 : getEntryByName(items, value).id,
            };
          });
        } else {
          // set team or role
          setPayload((prevState) => {
            return { ...prevState, [param]: getEntryByName(items, value).id };
          });
        }
      } else {
        // no changes
        setPayload((prevState) => {
          return { ...prevState, [param]: null };
        });
      }
    } else {
      // invalid input
      setPayload((prevState) => {
        return { ...prevState, [param]: null };
      });
    }
  }, [value, defaultValue, items, param, setPayload]);

  return (
    <FormControl required color='secondary'>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        color='secondary'
        defaultValue={defaultValue}
        onChange={(e) => handleChange(e)}
      >
        {items.map((item, index) => {
          return (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          );
        })}
        {param === 'defaultLocationId' && (
          <MenuItem value={'Offsite'}>Offsite</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectionInput;
