import React from 'react';
import DateWidget from '../date/DateWidget';
import OwnProfile from '../profile/OwnProfile';
import Calendar from '../calendar/Calendar';
import Logo from '../logo/Logo';
import SettingsMenu from '../settings/SettingsMenu';

const MenuArea = ({ children, hided }) => {
  return (
    <div className={`menu-area${hided ? ' hided' : ''}`}>
      {hided !== true && (
        <>
          <DateWidget />
          <OwnProfile />
          <Calendar />
          <Logo />
          <SettingsMenu />
        </>
      )}
      {children}
    </div>
  );
};
export default MenuArea;
