import {
  SET_SETTINGS_MENU,
  SET_ALERT,
  SET_STATUS_MENU,
  SET_PROFILE_INFORMATION,
  SET_ERROR_PAGE,
  PUSH_ANIMATED_PROFILE,
  REMOVE_ANIMATED_PROFILE,
  SET_REFRESH_PAGE_HINT,
  SET_SIDEBAR_HIDED,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case SET_SETTINGS_MENU:
      return {
        ...state,
        settingsMenu: action.payload,
        backgroundOpacity: action.payload,
      };
    case SET_STATUS_MENU:
      return {
        ...state,
        statusMenuUser: action.payload,
        backgroundOpacity: action.payload,
      };
    case SET_PROFILE_INFORMATION:
      return {
        ...state,
        profileInformationUser: action.payload,
        backgroundOpacity: action.payload,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case SET_ERROR_PAGE:
      return {
        ...state,
        errorPage: action.payload,
      };
    case PUSH_ANIMATED_PROFILE:
      return {
        ...state,
        animatedUsers: action.payload,
      };
    case REMOVE_ANIMATED_PROFILE:
      return {
        ...state,
        animatedUsers: action.payload,
      };
    case SET_REFRESH_PAGE_HINT:
      return {
        ...state,
        refreshPageHint: action.payload,
        backgroundOpacity: action.payload,
      };
    case SET_SIDEBAR_HIDED:
      return {
        ...state,
        sidebarHided: action.payload,
        backgroundOpacity:
          action.payload === null
            ? state.backgroundOpacity
              ? !state.backgroundOpacity
              : state.backgroundOpacity
            : !action.payload,
      };
    default:
      return state;
  }
};
