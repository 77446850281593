import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import ProfileContext from '../../../context/profile/profileContext';
import LayoutContext from '../../../context/layout/layoutContext';

const ConfigSettings = () => {
  // context
  const { config, setConfig, user } = useContext(ProfileContext);
  const { setAlert } = useContext(LayoutContext);

  // state
  const [defaultVal] = useState(JSON.stringify(config, null, 4));
  const [currentVal, setCurrentVal] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const handleInputChange = (value) => {
    try {
      setCurrentVal(JSON.parse(value));
      setDisabled(false);
    } catch (error) {
      setCurrentVal(null);
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    if (currentVal !== null) {
      setConfig('setConfig', user.name, JSON.stringify(currentVal, null, 4));
    }
    setDisabled(true);
  };

  return (
    <div className='settings-wrapper'>
      <div className='settings-form config'>
        <div className='config-area'>
          <code>
            <textarea
              onChange={(e) => handleInputChange(e.target.value)}
              defaultValue={defaultVal}
              spellCheck='false'
            ></textarea>
          </code>
        </div>
      </div>
      <div className='submitSettings config'>
        <Button
          disabled={disabled}
          onClick={() => handleSubmit(currentVal)}
          variant='contained'
          color='secondary'
          component='span'
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default ConfigSettings;
