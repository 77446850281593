import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../shared/TabPanel';
import CloseIcon from '@material-ui/icons/Close';
import EmployeeSettings from './EmployeeSettings';
import StatusSettings from './StatusSettings';
import ConfigSettings from './ConfigSettings';
import LayoutContext from '../../../context/layout/layoutContext';
import ProfileContext from '../../../context/profile/profileContext';
import TabMenuWrapper from '../../shared/TabMenuWrapper';

const AdminSettings = () => {
  // context
  const {
    syncGeneralData,
    config,
    profileItems,
    locationItems,
    statusItems,
    teamItems,
    roleItems,
  } = useContext(ProfileContext);
  const { setSettingsMenu } = useContext(LayoutContext);

  // state
  const [value, setValue] = useState(0);

  // sync data when opening Admin Space
  useEffect(() => {
    syncGeneralData();
  }, []);

  // change current tab
  const handleChange = (event, newValue) => {
    // 'event' is required by MUI
    setValue(newValue);
  };

  return (
    <TabMenuWrapper clickAwayHandler={() => setSettingsMenu(null)}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        <Tab label='Mitarbeiter' />
        <Tab label='Status' />
        <Tab label='config.json' />
      </Tabs>
      <CloseIcon
        className='close-button'
        onClick={() => setSettingsMenu(null)}
      />
      <TabPanel value={value} index={0}>
        <EmployeeSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StatusSettings />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ConfigSettings />
      </TabPanel>
    </TabMenuWrapper>
  );
};

export default AdminSettings;
