import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import ProfileContext from '../../../context/profile/profileContext';

const PasswordForm = ({ profile }) => {
  // context
  const { updateProfile } = useContext(ProfileContext);

  // state
  const [password, setPassword] = useState('');
  const [confirmValue, setConfirmValue] = useState('');
  const [buttonDisabled, disableButton] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  // effect
  useEffect(() => {
    if (password !== '' && password === confirmValue) {
      disableButton(false);
    } else {
      disableButton(true);
    }
  }, [password, confirmValue]);

  // handle submit click
  const handleSubmit = () => {
    let payload = {
      id: profile.id,
      passwordHash: password,
    };
    updateProfile(payload);
    setRefreshKey((prevKey) => prevKey + 1);
    disableButton(true);
  };

  return (
    <div key={refreshKey} className='settings-form'>
      <div className='password-form'>
        <TextField
          required
          id='outlined-required'
          type='password'
          label='Neues Passwort'
          color='secondary'
          variant='outlined'
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          required
          id='outlined-required'
          type='password'
          label='Passwort Bestätigen'
          color='secondary'
          variant='outlined'
          onChange={(e) => setConfirmValue(e.target.value)}
        />
      </div>
      <div className='submitSettings'>
        <Button
          disabled={buttonDisabled}
          variant='contained'
          color='secondary'
          onClick={handleSubmit}
        >
          Passwort ändern
        </Button>
      </div>
    </div>
  );
};

export default PasswordForm;
