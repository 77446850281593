import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingAnimation = ({ styles }) => (
  <div style={styles} className='loading-animation'>
    <CircularProgress color='secondary' />
  </div>
);

export default LoadingAnimation;
