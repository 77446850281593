import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import initialImage from '../../../images/default.jpeg';

const ImageUpload = ({ image, setEditorRef, scaleValue, onScaleChange }) => {
  // TODO: replace initialImage with current profile picture of selected profile
  return (
    <div className='image-editor-wrapper'>
      <AvatarEditor
        ref={setEditorRef}
        image={image ? image : initialImage}
        width={190}
        height={190}
        borderRadius={125}
        border={20}
        color={[255, 255, 255, 0.7]} // RGBA
        scale={scaleValue}
        rotate={0}
      />
      <input
        type='range'
        value={scaleValue * 10}
        name='points'
        min='5'
        max='100'
        onChange={onScaleChange}
      />
    </div>
  );
};

export default ImageUpload;
