import React, { useReducer } from 'react';
import ThemeContext from './themeContext';
import ThemeReducer from './themeReducer';
import MuiThemeProvider from './MuiThemeProvider';
import { CHANGE_THEME } from './types';

const ThemeState = (props) => {
  const initialState = {
    themeLight: true,
  };

  const [state, dispatch] = useReducer(ThemeReducer, initialState);

  const changeTheme = () =>
    dispatch({ type: CHANGE_THEME, payload: state.themeLight });

  // TODO: UseEffect (didUpdate => setLocal Storage => DidMount has new value)

  return (
    <ThemeContext.Provider
      value={{
        themeLight: state.themeLight,
        changeTheme,
      }}
    >
      <MuiThemeProvider
        children={props.children}
        themeLight={state.themeLight}
      ></MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeState;
