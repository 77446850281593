import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../templates';

const endpoint = 'Team';

export const getTeam = async () => {
  return getRequest(endpoint).then((response) => {
    return response;
  });
};

export const getTeamById = async (id) => {
  return getRequest(`${endpoint}/${id}`).then((response) => {
    return response;
  });
};

export const addTeam = (data) => {
  postRequest(endpoint, data).then((response) => {
    console.log(response);
  });
};

export const updateTeam = (data) => {
  putRequest(endpoint, data).then((response) => {
    console.log(response);
  });
};

export const deleteTeam = (id) => {
  deleteRequest(`${endpoint}/${id}`).then((response) => {
    console.log(response);
  });
};
