import React, { useState, useContext } from 'react';
import ImageUpload from './ImageUpload';
import defaultImage from '../../../images/default.jpeg';
import Button from '@material-ui/core/Button';
import FormData from 'form-data';
import { addImage, deleteImage } from '../../../utils/api/endpoints/image';
import ProfileContext from '../../../context/profile/profileContext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ImageMenu = ({ profile, setImageMenu }) => {
  // context
  const { addProfileImage, deleteProfileImage } = useContext(ProfileContext);
  // state
  const [editor, setEditor] = useState(null);
  const [selectedImage, setImage] = useState(null);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [scaleValue, setScaleValue] = useState(1);
  const [disabled, setDisabled] = useState(false);

  const setEditorRef = (editor) => setEditor(editor);

  // handle click on submit button
  const handleSubmit = () => {
    if (imageRemoved) {
      deleteProfileImage();
    } else {
      let formData = new FormData();
      let image = croppedImage();

      formData.append('file', image);
      // TODO: put in profile state later
      setDisabled(true);
      addProfileImage(profile.id, formData);
    }
  };

  // handle removing of current image
  const handleRemoveImage = () => {
    setImage(defaultImage);
    setImageRemoved(true);
  };

  // handle image cropping
  const croppedImage = () => {
    if (editor !== null) {
      // define image source
      let image = editor.getImage();

      // define destination canvas
      let canvas = document.createElement('canvas');
      canvas.width = '500';
      canvas.height = '500';

      let ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, 500, 500);

      const url = canvas.toDataURL();
      const file = dataURLtoFile(url, 'image.jpeg');

      //downloadURI(canvas.toDataURL(), 'image.jpeg');

      return file;
    }
  };

  function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // handle scale change
  const onScaleChange = (scaleChangeEvent) => {
    const scaleValue = parseFloat(scaleChangeEvent.target.value);
    setScaleValue(scaleValue / 10);
  };

  // handle change of selected image
  const handleImageChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    const { type } = file;
    if (
      !(
        type.endsWith('jpeg') ||
        type.endsWith('png') ||
        type.endsWith('jpg') ||
        type.endsWith('gif')
      )
    ) {
      // TODO: Fehlermeldung
    } else {
      setImage(file);
      setImageRemoved(false);
    }
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  // create file by data URL
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log(mime);
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className='image-menu'>
      <input
        type='file'
        accept='image/png, image/jpg, image/jpeg, image/gif'
        onChange={handleImageChange}
      />
      <ImageUpload
        image={selectedImage}
        setEditorRef={setEditorRef}
        scaleValue={scaleValue}
        onScaleChange={onScaleChange}
      />
      <ArrowBackIosIcon
        className='back-button'
        onClick={() => setImageMenu(false)}
      />
      <div className='submitSettings'>
        <Button
          disabled={selectedImage === null || disabled}
          variant='contained'
          color='secondary'
          onClick={() => handleSubmit()} // TODO: API -> Image Controller
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default ImageMenu;
