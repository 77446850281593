import React, { useContext, useState, Fragment, useEffect } from 'react';
import ProfileWithMenu from './ProfileWithMenu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ProfileContext from '../../context/profile/profileContext';
import { getEntryByName } from '../../utils/api/getEntry';
import { isTouchDevice } from '../../utils/helpers/isTouchDevice';

const OwnProfile = () => {
  // context
  const {
    user,
    locationItems,
    updateProfileLocation,
    updateProfileStatusShortCut,
  } = useContext(ProfileContext);

  // state
  const [menuOverlayShown, setMenuOverlay] = useState(false);

  // click event on window
  useEffect(() => {
    window.addEventListener('click', handleClickEvent);
    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, [menuOverlayShown]);

  const handleClickEvent = (e) => {
    console.log(e.target);
    if (
      menuOverlayShown &&
      e.target !== document.querySelector('.ownProfile .profile-image')
    ) {
      console.log('Event Click');
      setMenuOverlay(false);
    }
  };

  // handle short click on own profile
  const handleClick = (profile) => {
    if (isTouchDevice()) {
      if (menuOverlayShown) {
        // change status via shortcut
        updateProfileStatusShortCut(profile);
        setMenuOverlay(false);
      } else {
        // open status menu on touch device
        setMenuOverlay(true);
      }
    } else {
      // change status via shortcut - no touch device (hover works)
      updateProfileStatusShortCut(profile);
    }
  };

  // handle location change
  const handleLocationChange = (location) => {
    const locationId =
      location !== 'Offsite' ? getEntryByName(locationItems, location).id : 0;

    updateProfileLocation({
      id: user.id,
      locationId,
    });
  };

  return (
    <div className='ownProfile'>
      {user.role !== 'Device' && (
        <Fragment>
          <ProfileWithMenu
            profile={user}
            removeMenuOverlay={() => setMenuOverlay(false)}
            setMenuOverlay={() => {
              if (!isTouchDevice()) {
                console.log('YES1');
                setMenuOverlay(true);
              }
            }}
            menuOverlayShown={menuOverlayShown}
            handleClick={handleClick}
          />
          {!menuOverlayShown && (
            <div className='location'>
              <FormControl color='secondary'>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={user.location}
                  color='secondary'
                  onChange={(e) => {
                    handleLocationChange(e.target.value);
                  }}
                >
                  {locationItems.map((location, index) => {
                    return (
                      <MenuItem key={index} value={location.name}>
                        {location.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={'Offsite'}>Offsite</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OwnProfile;
