import React from 'react';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import HealingIcon from '@material-ui/icons/Healing';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';

const Icon = ({ name }) => {
  const icons = {
    unterwegs: <FlightTakeoffIcon />,
    urlaub: <BeachAccessIcon />,
    elternzeit: <ChildFriendlyIcon />,
    krank: <HealingIcon />,
    pause: <LocalCafeIcon />,
    homeoffice: <HomeIcon />,
    login: <ExitToAppIcon />,
    logout: <ExitToAppIcon />,
    cancel: <CancelIcon />,
    location: <LocationOnIcon />,
    settings: <SettingsIcon />,
  };
  return icons[name];
};
export default Icon;
