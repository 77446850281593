import { GET_ALL_DATA, SET_LOADING } from './types';

export default (state, action) => {
  switch (action.type) {
    case GET_ALL_DATA:
      return {
        ...state,
        user: action.payload.user,
        profileItems: action.payload.profileItems,
        locationItems: action.payload.locationItems,
        statusItems: action.payload.statusItems,
        roleItems: action.payload.roleItems,
        teamItems: action.payload.teamItems,
        config: action.payload.config,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
