import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../templates';

const endpoint = 'Location';

export const getLocation = async () => {
  return getRequest(endpoint).then((response) => {
    return response;
  });
};

export const getLocationById = async (id) => {
  return getRequest(`${endpoint}/${id}`).then((response) => {
    return response;
  });
};

export const addLocation = (data) => {
  postRequest(endpoint, data).then((response) => {
    console.log(response);
  });
};

export const updateLocation = (data) => {
  putRequest(endpoint, data).then((response) => {
    console.log(response);
  });
};

export const deleteLocation = (id) => {
  deleteRequest(`${endpoint}/${id}`).then((response) => {
    console.log(response);
  });
};
