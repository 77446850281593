import React, { useContext } from 'react';
import LoginForm from './LoginForm';
import AlertSnackbar from '../shared/AlertSnackbar';
import ThemeContext from '../../context/theme/themeContext';
import LayoutContext from '../../context/layout/layoutContext';

const LoginScreen = () => {
  // context
  const { themeLight } = useContext(ThemeContext);
  const { alert, setAlert } = useContext(LayoutContext);

  // styling
  const className = themeLight ? 'light' : 'dark';

  return (
    <div className={'loginScreen flex-row ' + className}>
      <div className='loginContainer'>
        <div className='loginForm-main'>
          <h1>Anmelden</h1>
          <LoginForm />
        </div>
      </div>
      {alert && (
        <AlertSnackbar alert={alert} handleClose={() => setAlert(null)} />
      )}
    </div>
  );
};

export default LoginScreen;
