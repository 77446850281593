export const getEntry = (list, id) => {
  let entry;

  list.forEach((item) => {
    if (item.id === id) {
      entry = item;
    }
  });

  return typeof entry != 'undefined' ? entry : false;
};

export const getEntryByName = (list, name) => {
  let entry;

  list.forEach((item) => {
    if (item.name.toLowerCase() === name.toLowerCase()) {
      entry = item;
    }
  });

  return typeof entry != 'undefined' ? entry : false;
};
