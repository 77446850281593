import { getAllUsers, getChangedUsers, getUserById } from './endpoints/user';
import { getLocation } from './endpoints/location';
import { getStatus } from './endpoints/status';
import { getTeam } from './endpoints/team';
import { getRole } from './endpoints/role';
import { getEntry } from './getEntry';
import { postAssetFunctions } from '../../utils/api/templates';

// fetch all required profile data
export const fetchAllData = async () => {
  async function dataFetch() {
    let data = [];

    // get all tables referenced in user table
    data.locationItems = await getLocation();
    data.statusItems = await getStatus();
    data.teamItems = await getTeam();
    data.roleItems = await getRole();

    // get user
    let user = await getUserById(0);

    // get status config file
    data.config = await postAssetFunctions('getConfig', user.name);

    if (!Object.values(data).includes(false)) {
      console.log('NO ERROR');

      // map user attributes
      data.user = mapProfileAttributes(user, data);

      // map attributes of other profiles
      data.profileItems = [];
      let profileItems = await getAllUsers();

      for (let i = 0; i < profileItems.length; i++) {
        data.profileItems[i] = mapProfileAttributes(profileItems[i], data);
      }

      console.log(data);
      return data;
    } else {
      console.log('ERROR OCCURED');
      // error while data fetching
      return false;
    }
  }

  return await dataFetch();
};

// fetch only the changed profile data
export const fetchProfileChanges = async (prevData) => {
  async function dataFetch() {
    let newData = JSON.parse(JSON.stringify(prevData));

    // get changed profiles
    let changedProfiles = await getChangedUsers();

    if (changedProfiles !== false) {
      if (changedProfiles.length > 0) {
        // there are changes - map profile attributes
        for (let i = 0; i < changedProfiles.length; i++) {
          if (changedProfiles[i].id === prevData.user.id) {
            let mappedData = mapProfileAttributes(changedProfiles[i], prevData);
            if (mappedData !== false) {
              newData.user = mappedData;
            } else {
              // there are general changes in database - fetch all data
              console.log('YES');
              return fetchAllData();
            }
          } else {
            for (let j = 0; j < newData.profileItems.length; j++) {
              if (changedProfiles[i].id === newData.profileItems[j].id) {
                let mappedData = mapProfileAttributes(
                  changedProfiles[i],
                  prevData
                );
                if (mappedData !== false) {
                  newData.profileItems[j] = mappedData;
                } else {
                  // there are general changes in database - fetch all data
                  console.log('YES1');
                  return fetchAllData();
                }
              }
            }
          }
        }
      }
      console.log(newData);
      return newData;
    } else {
      console.log('ERROR OCCURED');
      // error while data fetching
      return false;
    }
  }
  return await dataFetch();
};

// map actual values from other tables by given ID's
const mapProfileAttributes = (profile, data) => {
  let profile_item = Object.assign({}, profile);

  // set location
  if (profile_item.locationId !== null) {
    let location = getEntry(data.locationItems, profile_item.locationId);

    profile_item.location = location ? location.name : location;
  } else {
    profile_item.location = 'Offsite';
  }

  // set default location
  if (profile_item.defaultLocationId !== null) {
    let defaultLocation = getEntry(
      data.locationItems,
      profile_item.defaultLocationId
    );

    profile_item.defaultLocation = defaultLocation
      ? defaultLocation.name
      : defaultLocation;
  } else {
    profile_item.defaultLocation = 'Offsite';
  }

  // set status value
  let statusValue = getEntry(data.statusItems, profile_item.statusId);
  profile_item.status = statusValue ? statusValue.name : statusValue;

  // set role value
  let roleValue = getEntry(data.roleItems, profile_item.roleId);
  profile_item.role = roleValue ? roleValue.name : roleValue;

  // set team value
  let teamValue = getEntry(data.teamItems, profile_item.teamId);
  profile_item.team = teamValue ? teamValue.name : teamValue;

  if (Object.values(profile_item).includes(false)) {
    // at least one id is not available
    return false;
  } else {
    return profile_item;
  }
};
