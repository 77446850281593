import { getRequest } from '../templates';

const endpoint = 'Role';

export const getRole = async () => {
  return getRequest(endpoint).then((response) => {
    return response;
  });
};

export const getRoleById = async (id) => {
  return getRequest(`${endpoint}/${id}`).then((response) => {
    return response;
  });
};
