import React from 'react';
import ReactDOM from 'react-dom';
import Icon from '@material-ui/core/Icon';

const RefreshHint = () =>
  ReactDOM.createPortal(
    <div id='refresh-hint'>
      Du scheinst WAYNe innerhalb eines neuen Tabs zu nutzen. Verwende jenen Tab
      oder aktualisiere diese Seite.
      <div id='refreshButton-wrapper'>
        <Icon onClick={() => window.location.reload(true)} id='refreshButton'>
          cached
        </Icon>
      </div>
    </div>,
    document.getElementById('refresh-hint-portal')
  );

export default RefreshHint;
