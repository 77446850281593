import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../templates';

const endpoint = 'Status';

export const getStatus = async () => {
  return await getRequest(endpoint);
};

export const getStatusById = async (id) => {
  return await getRequest(`${endpoint}/${id}`);
};

export const addStatus = async (data) => {
  return await postRequest(endpoint, data);
};

export const updateStatus = async (data) => {
  return await putRequest(endpoint, data);
};

export const deleteStatus = async (id) => {
  return await deleteRequest(`${endpoint}/${id}`);
};
