import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProfileContext from '../../../context/profile/profileContext';

const DescriptionMenu = ({ profile, setDescriptionMenu }) => {
  // context
  const { updateProfile } = useContext(ProfileContext);

  // state
  const [descriptionValue, setDescription] = useState(
    profile.description ? profile.description : ''
  );
  const [buttonDisabled, disableButton] = useState(false);

  // effect
  useEffect(() => {
    disableButton(false);
  }, [descriptionValue]);

  // handle submit click
  const handleSubmit = () => {
    const payload = {
      id: profile.id,
      description: descriptionValue === '' ? 'null' : descriptionValue,
    };

    updateProfile(payload);
    disableButton(true);
  };

  return (
    <div className='description-menu'>
      <div className='description-area'>
        <textarea
          defaultValue={profile.description ? profile.description : ''}
          spellCheck='false'
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <ArrowBackIosIcon
        className='back-button'
        onClick={() => setDescriptionMenu(false)}
      />
      <div className='submitSettings'>
        <Button
          disabled={buttonDisabled}
          variant='contained'
          color='secondary'
          onClick={handleSubmit}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default DescriptionMenu;
