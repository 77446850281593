import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CakeIcon from '@material-ui/icons/Cake';
import GroupIcon from '@material-ui/icons/Group';
import TabPanel from '../../components/shared/TabPanel';
import Profile from './Profile';
import CloseIcon from '@material-ui/icons/Close';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { monthDays } from '../../utils/helpers/useTimer';
import LayoutContext from '../../context/layout/layoutContext';
import TabMenuWrapper from '../shared/TabMenuWrapper';

const ProfileInformation = ({ user }) => {
  // context
  const { setProfileInformationUser } = useContext(LayoutContext);

  // state
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // 'event' is required by MUI
    setValue(newValue);
  };

  return (
    <TabMenuWrapper clickAwayHandler={() => setProfileInformationUser(null)}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        <Tab label={`${user.firstName} ${user.lastName}`} />
      </Tabs>
      <CloseIcon
        className='close-button'
        onClick={() => setProfileInformationUser(null)}
      />
      <TabPanel value={value} index={0}>
        <div className='profile-info-wrapper'>
          <div className='information-wrapper'>
            <div className='profile-item'>
              <Profile shortProfile={true} profile={user} />
            </div>
            <div className='key-infos-wrapper'>
              <div>
                <LocationOnIcon color='secondary' />
                <p>{user.defaultLocation}</p>
              </div>
              <div>
                <GroupIcon color='secondary' />
                <p>{user.team}</p>
              </div>
              <div>
                <CakeIcon className='birthday' color='secondary' />
                <p>{user.birthDate ? formatDate(user.birthDate) : 'N/A'}</p>
              </div>
              <div>
                <a
                  href={
                    user.phoneNumber &&
                    `tel:${formatPhoneNumberIntl(user.phoneNumber)}`
                  }
                >
                  <PhoneIcon className='phone-number' color='secondary' />
                </a>

                <p>
                  {user.phoneNumber
                    ? formatPhoneNumberIntl(user.phoneNumber)
                    : 'N/A'}
                </p>
              </div>
            </div>
          </div>
          <div className='description-wrapper'>
            <div className='description-headline'>Kurzbeschreibung</div>
            <div className='description-content'>
              <textarea
                disabled
                defaultValue={user.description ? user.description : 'N/A'}
                spellCheck='false'
              ></textarea>
            </div>
          </div>
        </div>
      </TabPanel>
    </TabMenuWrapper>
  );
};

export default ProfileInformation;

const formatDate = (date) => {
  const dateObj = new Date(date);

  // dateString
  const dayOfMonth = dateObj.getDate();
  const month = monthDays[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const dateString = `${dayOfMonth}. ${month} ${year}`;

  return dateString;
};
