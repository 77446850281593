import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';
import UserBottomNav from './UserBottomNav';
import EmployeeForm from '../EmployeeForm';
import PasswordForm from './PasswordForm';
import ImageMenu from './ImageMenu';
import DescriptionMenu from './DescriptionMenu';
import TabPanel from '../../shared/TabPanel';
import ProfileContext from '../../../context/profile/profileContext';
import LayoutContext from '../../../context/layout/layoutContext';
import TabMenuWrapper from '../../shared/TabMenuWrapper';

const UserSettings = () => {
  // context
  const { user } = useContext(ProfileContext);
  const { setSettingsMenu } = useContext(LayoutContext);

  // state
  const [value, setValue] = useState(0);
  const [imageMenuShown, setImageMenu] = useState(false);
  const [descriptionMenuShown, setDescriptionMenu] = useState(false);
  const [menuForm, setMenuForm] = useState(
    <EmployeeForm
      type='edit'
      setDescriptionMenu={setDescriptionMenu}
      setImageMenu={setImageMenu}
      profile={user}
      role='user'
    />
  );

  // handle switch in bottom navigation
  const handleMenuSwitch = (value) => {
    switch (value) {
      case 'Bearbeiten':
        setMenuForm(
          <EmployeeForm
            setDescriptionMenu={setDescriptionMenu}
            setImageMenu={setImageMenu}
            type='edit'
            profile={user}
            role='user'
          />
        );
        break;
      case 'Passwort':
        setImageMenu(false);
        setDescriptionMenu(false);
        setMenuForm(<PasswordForm profile={user} />);
        break;
      default:
      // do nothing
    }
  };

  // not used since we have just one tab
  const handleChange = (event, newValue) => {
    // 'event' is required by MUI
    setValue(newValue);
  };

  return (
    <TabMenuWrapper clickAwayHandler={() => setSettingsMenu(null)}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        <Tab label='Mein Profil' />
      </Tabs>
      <CloseIcon
        className='close-button'
        onClick={() => setSettingsMenu(null)}
      />
      <TabPanel value={value} index={0}>
        <div className='settings-wrapper'>
          {!imageMenuShown && !descriptionMenuShown && menuForm}
          {imageMenuShown && (
            <ImageMenu setImageMenu={setImageMenu} profile={user} />
          )}
          {descriptionMenuShown && (
            <DescriptionMenu
              setDescriptionMenu={setDescriptionMenu}
              profile={user}
            />
          )}
          <UserBottomNav handleMenuSwitch={handleMenuSwitch} />
        </div>
      </TabPanel>
    </TabMenuWrapper>
  );
};

export default UserSettings;
