import React from 'react';
import MenuOverlay from './MenuOverlay';
import Profile from './Profile';

const ProfileWithMenu = ({
  profile,
  menuOverlayShown,
  setMenuOverlay,
  removeMenuOverlay,
  removeStatusMenu,
  style,
  restricted,
  handleClick, // just for own profile
}) => {
  // close big status menu with click
  const handleClickAway = () => {
    if (typeof removeStatusMenu === 'function') {
      // other profile
      removeStatusMenu();
    }
  };

  return (
    <div
      onClick={handleClickAway}
      className={`status-menu${restricted ? ' big' : ''}`}
      style={style}
    >
      {profile && (
        <MenuOverlay
          restricted={restricted}
          profile={profile}
          menuOverlayShown={menuOverlayShown}
          removeMenuOverlay={removeMenuOverlay}
          removeStatusMenu={removeStatusMenu}
        />
      )}
      {profile && (
        <Profile
          setMenuOverlay={setMenuOverlay}
          profile={profile}
          menuOverlayShown={menuOverlayShown}
          withMenu={true}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export default ProfileWithMenu;
