import React, { useReducer, useContext } from 'react';
import LoginContext from './loginContext';
import LoginReducer from './loginReducer';
import { login } from '../../utils/api/endpoints/account';
import { getUserById } from '../../utils/api/endpoints/user';
import {
  GET_LOGIN_STATE,
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  SET_LOADING,
} from './types';
import LayoutContext from '../../context/layout/layoutContext';

const LoginState = (props) => {
  const initialState = {
    loggedIn: false,
    loading: true,
  };

  const [state, dispatch] = useReducer(LoginReducer, initialState);
  const { setAlert } = useContext(LayoutContext);

  // get initial login state by jwt
  const getLoginState = () => {
    const token = localStorage.getItem('token');
    const token_set = token !== null;

    if (token_set) {
      console.log('TOKEN SET');
      getUserById(0).then((res) => {
        if (res) {
          console.log('VALID TOKEN');
          dispatch({ type: GET_LOGIN_STATE, payload: true });
        } else {
          console.log('INVALID TOKEN OR SERVER NOT CONNECTED');
          dispatch({ type: GET_LOGIN_STATE, payload: false });
        }
      });
    } else {
      console.log('TOKEN NOT SET');
      dispatch({ type: GET_LOGIN_STATE, payload: false });
    }
  };

  // validate login trial
  const handleLogin = (username, password) => {
    dispatch({ type: SET_LOADING });
    login(username, password).then((res) => {
      if (!res) {
        // login error - display alert
        setAlert({ text: 'loginError', type: 'error' });
      }
      dispatch({ type: HANDLE_LOGIN, payload: res });
    });
  };

  // logout user - remove jwt
  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: HANDLE_LOGOUT });
  };

  return (
    <LoginContext.Provider
      value={{
        loggedIn: state.loggedIn,
        loading: state.loading,
        getLoginState,
        handleLogin,
        handleLogout,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginState;
