import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Profile from './Profile';
import LayoutContext from '../../context/layout/layoutContext';
import ProfileContext from '../../context/profile/profileContext';

const ProfileSection = ({ profileItems }) => {
  // context
  const { user, updateProfileStatusShortCut } = useContext(ProfileContext);
  const { setStatusMenuUser, setProfileInformationUser } = useContext(
    LayoutContext
  );

  // handle short click on profile
  const handleClick = (profile) => {
    if (user.role !== 'Device') {
      if (window.innerWidth >= 650) {
        // if no device and width > 650px -> show short profile
        setProfileInformationUser(profile);
      }
    } else {
      // if device -> change status via shortcut
      updateProfileStatusShortCut(profile);
    }
  };

  return (
    <div className='profile-section'>
      {profileItems.map(
        (profile, index) =>
          // do not render own profile
          profile.id !== user.id &&
          profile.role !== 'Device' && (
            <Profile
              handleClick={handleClick}
              handleLongPress={user.role !== 'User' && setStatusMenuUser}
              profile={profile}
              key={index}
            />
          )
      )}
    </div>
  );
};

export default ProfileSection;

// propTypes
ProfileSection.propTypes = {
  profileItems: PropTypes.array,
};
