import React, { useState, useContext, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/icons/Edit';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';
import LayoutContext from '../../context/layout/layoutContext';
import LoginContext from '../../context/login/loginContext';
import ProfileContext from '../../context/profile/profileContext';

const SettingsMenu = () => {
  // context
  const { setSettingsMenu } = useContext(LayoutContext);
  const { handleLogout } = useContext(LoginContext);
  const { user } = useContext(ProfileContext);

  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutDisabled, setLogoutDisabled] = useState(false);

  // handle click on settings icon
  const handleClick = (event) => {
    setLogoutDisabled(true);
    setAnchorEl(event.currentTarget);

    // short delay before enabling logout button
    setTimeout(() => {
      setLogoutDisabled(false);
    }, 250);
  };

  // handle action after closing menu / selection
  const handleClose = (menu) => {
    switch (menu) {
      case 'userSettings':
        setSettingsMenu('user');
        break;
      case 'adminSettings':
        setSettingsMenu('admin');
        break;
      case 'logout':
        handleLogout();
        break;
      default:
      // do nothing
    }
    setAnchorEl(null);
  };

  return (
    <>
      {window.innerWidth > 1000 ? (
        <div className='settings-btn'>
          <Icon
            aria-controls='settings-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            settings
          </Icon>
          <Menu
            id='settings-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {user.role !== 'Device' && (
              <MenuItem onClick={() => handleClose('userSettings')}>
                <ListItemIcon>
                  <EditIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Profil bearbeiten' />
              </MenuItem>
            )}
            {user.role !== 'Device' && user.role !== 'User' && (
              <MenuItem onClick={() => handleClose('adminSettings')}>
                <ListItemIcon>
                  <BuildIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Admin Space' />
              </MenuItem>
            )}
            <MenuItem
              disabled={logoutDisabled}
              onClick={() => handleClose('logout')}
            >
              <ListItemIcon>
                <ExitToAppIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Ausloggen' />
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <LogoutButton handleLogout={handleLogout} />
      )}
    </>
  );
};

export default SettingsMenu;

const LogoutButton = ({ handleLogout }) => {
  return (
    <div onClick={handleLogout} className='settings-btn'>
      <Icon>exit_to_app</Icon>
    </div>
  );
};
