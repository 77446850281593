import { initPostRequest } from '../templates';

export const login = async (username, password) => {
  const data = {
    name: username,
    passwordhash: password,
  };

  return initPostRequest('Account', data).then((response) => {
    localStorage.setItem('token', response);
    if (response !== null) {
      return true;
    } else {
      console.error('Falsche Anmeldedaten');
      return false;
    }
  });
};
