import {
  GET_LOGIN_STATE,
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  SET_LOADING,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_STATE:
      return {
        ...state,
        loggedIn: action.payload,
        loading: false,
      };
    case HANDLE_LOGIN:
      return {
        ...state,
        loggedIn: action.payload,
        loading: false,
      };
    case HANDLE_LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};
