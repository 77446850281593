import React, { useContext, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import Icon from '../shared/Icon';
import LoginContext from '../../context/login/loginContext';

const LoginForm = () => {
  // context
  const { handleLogin } = useContext(LoginContext);

  // state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // submit via enter
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin(username, password);
    }
  };

  return (
    <form
      onKeyDown={(e) => handleKeyDown(e)}
      className='loginForm flex-col'
      noValidate
      autoComplete='off'
    >
      <TextField
        className='loginItem username'
        label='Benutzername'
        variant='filled'
        color='secondary'
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        className='loginItem password'
        type='password'
        label='Passwort'
        variant='filled'
        color='secondary'
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        className='loginButton'
        variant='contained'
        color='primary'
        startIcon={<Icon name='login' />}
        onClick={() => handleLogin(username, password)}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
