import React, { useContext } from 'react';
import DateWidget from '../date/DateWidget';
import SettingsMenu from '../settings/SettingsMenu';
import Logo from '../logo/Logo';
import Icon from '@material-ui/core/Icon';
import LoginContext from '../../context/login/loginContext';

const TopMenuArea = () => {
  return (
    <div className='menu-area device'>
      <Logo />
      <div className='settings-date-wrapper'>
        <LogoutButton />
        <DateWidget />
      </div>
    </div>
  );
};
export default TopMenuArea;

const LogoutButton = () => {
  // context
  const { handleLogout } = useContext(LoginContext);

  return (
    <div className='logout-btn'>
      <Icon onClick={() => handleLogout()}>exit_to_app</Icon>
    </div>
  );
};
