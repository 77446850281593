import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const AlertSnackbar = ({
  alert,
  handleClose,
  anchorOrigin,
  autoHideDuration,
}) => {
  // set alert text
  let alertText;
  switch (alert.text) {
    case 'loginError':
      alertText = 'Ungültige Anmeldedaten!';
      break;
    case 'changeSuccess':
      alertText = 'Änderungen gespeichert!';
      break;
    case 'changeFailure':
      alertText = 'Änderungen konnten nicht gespeichert werden!';
      break;
    case 'statusChangeFailure':
      alertText = 'Status konnte nicht geändert werden!';
      break;
    case 'locationChangeFailure':
      alertText = 'Standort konnte nicht geändert werden!';
      break;
    case 'profile_add':
      alertText = 'Profil angelegt!';
      break;
    case 'profile_delete':
      alertText = 'Profil gelöscht!';
      break;
    case 'status_add':
      alertText = 'Status hinzugefügt!';
      break;
    case 'status_delete':
      alertText = 'Status gelöscht!';
      break;
    default:
      alertText = '';
  }

  return ReactDOM.createPortal(
    <div>
      <Snackbar
        className={'alert-snackbar'}
        open={true}
        autoHideDuration={autoHideDuration ? autoHideDuration : 4000}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : { vertical: 'bottom', horizontal: 'center' }
        }
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleClose}
          severity={alert.type}
        >
          {alertText}
        </MuiAlert>
      </Snackbar>
    </div>,
    document.getElementById('alert-portal')
  );
};

export default AlertSnackbar;
