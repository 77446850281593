import React, { useContext } from 'react';
import ProfileSection from '../profile/ProfileSection';
import ProfileContext from '../../context/profile/profileContext';
import {
  filterByLocation,
  sortAlphabetically,
  sortByAvailability,
} from '../../utils/helpers/filterProfiles';

const ProfileArea = ({ locationFilter, children }) => {
  // context
  const { profileItems, locationItems, config } = useContext(ProfileContext);

  // sort and filter profiles
  const sorted_profiles = sortAlphabetically(profileItems);
  const filtered_profiles = filterByLocation(sorted_profiles, locationItems);

  return (
    <div className='profile-area'>
      {locationFilter ? (
        Object.entries(filtered_profiles).map(
          ([location, locationSet], index) => (
            <div
              key={index}
              className={`location-wrapper ${location.toLowerCase()}`}
            >
              <div className={`location-image ${location.toLowerCase()}`}></div>
              <ProfileSection
                profileItems={
                  location !== 'Offsite'
                    ? locationSet
                    : // profiles in offsite should be sorted
                      sortByAvailability(locationSet, config)
                }
              />
            </div>
          )
        )
      ) : (
        // no location filter set
        <ProfileSection profileItems={sorted_profiles} />
      )}
      {children}
    </div>
  );
};

export default ProfileArea;
