import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../templates';

const endpoint = 'User';

export const getAllUsers = async () => {
  return await getRequest(`${endpoint}/GetAll`);
};

export const getChangedUsers = async () => {
  const timestamp = localStorage.getItem('timestamp');
  // ADJUST when new backend version is online
  return await getRequest(`${endpoint}/GetChanged/${timestamp}`);
};

export const getUserById = async (id) => {
  return await getRequest(`${endpoint}/Get/${id}`);
};

export const addUser = async (data) => {
  return await postRequest(`${endpoint}/Add`, data);
};

export const updateUser = async (data) => {
  return await putRequest(`${endpoint}/Update`, data);
};

export const deleteUser = async (id) => {
  return await deleteRequest(`${endpoint}/Delete/${id}`);
};
