import React, { useEffect, useContext } from 'react';
import MainScreen from './layout/MainScreen';
import LoginScreen from './login/LoginScreen';
import LoadingAnimation from '../components/shared/LoadingAnimation';
import ProfileState from '../context/profile/ProfileState';
import LoginContext from '../context/login/loginContext';
import '../styles/main.scss';

const App = () => {
  const { getLoginState, loggedIn, loading } = useContext(LoginContext);

  // init login state
  useEffect(() => {
    getLoginState();
    // eslint-disable-next-line
  }, []);

  if (loggedIn) {
    return (
      <ProfileState>
        <MainScreen />
      </ProfileState>
    );
  } else {
    if (loading) {
      return <LoadingAnimation />;
    }
    return <LoginScreen />;
  }
};

export default App;
