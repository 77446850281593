import React, { useReducer, useEffect, useState } from 'react';
import LayoutContext from './layoutContext';
import LayoutReducer from './layoutReducer';
import {
  SET_SETTINGS_MENU,
  SET_STATUS_MENU,
  SET_PROFILE_INFORMATION,
  SET_ALERT,
  SET_ERROR_PAGE,
  PUSH_ANIMATED_PROFILE,
  REMOVE_ANIMATED_PROFILE,
  SET_REFRESH_PAGE_HINT,
  SET_SIDEBAR_HIDED,
} from './types';

const LayoutState = (props) => {
  const initialState = {
    settingsMenu: null,
    locationFilter: true,
    statusMenuUser: null,
    profileInformationUser: null,
    backgroundOpacity: false,
    serverTimeoutMessage: false,
    alert: null,
    errorPage: null,
    animatedUsers: [],
    refreshPageHint: false,
    sidebarHided:
      window.innerWidth <= 1000 &&
      document.querySelectorAll('.content .device').length === 0
        ? true
        : null,
  };

  const [state, dispatch] = useReducer(LayoutReducer, initialState);
  const [resized, setResized] = useState(
    window.innerWidth <= 1000 &&
      document.querySelectorAll('.content .device').length === 0
      ? true
      : false
  );

  useEffect(() => {
    if (document.querySelectorAll('.content .device').length === 0) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [resized]);

  const handleResize = () => {
    if (window.innerWidth > 1000) {
      setSidebarHided(null);
      setResized(false);
    } else {
      //alert(resized);
      if (!resized) {
        setSidebarHided(true);
        setResized(true);
      }
    }
  };

  const setSettingsMenu = (menuName) => {
    dispatch({ type: SET_SETTINGS_MENU, payload: menuName });
  };

  const setAlert = (alertName) => {
    dispatch({ type: SET_ALERT, payload: alertName });
  };

  const setProfileInformationUser = (user) => {
    dispatch({
      type: SET_PROFILE_INFORMATION,
      payload: user,
    });
  };

  const setStatusMenuUser = (user) => {
    dispatch({
      type: SET_STATUS_MENU,
      payload: user,
    });
  };

  const setErrorPage = (type) => {
    dispatch({
      type: SET_ERROR_PAGE,
      payload: type,
    });
  };

  const pushAnimatedUser = (userId, status) => {
    let animatedProfiles = state.animatedUsers;

    animatedProfiles.push({ userId, status });
    dispatch({
      type: PUSH_ANIMATED_PROFILE,
      payload: animatedProfiles,
    });
  };

  const removeAnimatedUser = () => {
    let animatedProfiles = state.animatedUsers;

    // remove first element of array
    animatedProfiles.shift();

    dispatch({
      type: REMOVE_ANIMATED_PROFILE,
      payload: animatedProfiles,
    });
  };

  const setRefreshPageHint = (bool) => {
    dispatch({
      type: SET_REFRESH_PAGE_HINT,
      payload: bool,
    });
  };

  const setSidebarHided = (bool) => {
    dispatch({
      type: SET_SIDEBAR_HIDED,
      payload: bool,
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        settingsMenu: state.settingsMenu,
        statusMenuUser: state.statusMenuUser,
        profileInformationUser: state.profileInformationUser,
        alert: state.alert,
        locationFilter: state.locationFilter,
        serverTimeoutMessage: state.serverTimeoutMessage,
        backgroundOpacity: state.backgroundOpacity,
        errorPage: state.errorPage,
        animatedUsers: state.animatedUsers,
        refreshPageHint: state.refreshPageHint,
        sidebarHided: state.sidebarHided,
        setAlert,
        setStatusMenuUser,
        setProfileInformationUser,
        setSettingsMenu,
        setErrorPage,
        pushAnimatedUser,
        removeAnimatedUser,
        setRefreshPageHint,
        setSidebarHided,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutState;
